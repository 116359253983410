import { IDogBreed } from 'models/common.model'
import { POPULAR_BREEDS_FOR_DROPDOWN } from 'pages/questions/dog-breed/constants'

export const getBreedsFromBreedsRaw = (
  breeds: IDogBreed[],
): Record<string, IDogBreed[]> => {
  const popularBreeds: IDogBreed[] = []

  const filteredBreeds = breeds.filter((breed) => {
    if (POPULAR_BREEDS_FOR_DROPDOWN.includes(breed.key)) {
      popularBreeds.push(breed)
    }

    return breed.key !== 'Test name'
  })

  const sortedPopularBreeds = popularBreeds.sort((a, b) => a.order - b.order)

  return { popularBreeds: sortedPopularBreeds, breeds: filteredBreeds }
}
