import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { selectOptimizeExperimentId } from 'root-redux/selects/common'
import { setOptimizeVariantIdAction } from 'root-redux/actions/common'

export const useSetOptimizeVariantId = (): void => {
  const dispatch = useDispatch()
  const googleOptimizeExperimentId = useSelector(selectOptimizeExperimentId)

  useEffect(() => {
    const setOptimizePageId = (value) =>
      dispatch(setOptimizeVariantIdAction(value))

    if (googleOptimizeExperimentId && window && window.gtag) {
      window.gtag('event', 'optimize.callback', {
        name: googleOptimizeExperimentId,
        callback: setOptimizePageId,
      })
    }
  }, [dispatch, googleOptimizeExperimentId])
}
