export const firebaseConfigDEV = {
  apiKey: 'AIzaSyAb-GfwbkSuTRP9Uz1FK33fuh1k0ZAO9Mg',
  authDomain: 'woofz-dev.firebaseapp.com',
  projectId: 'woofz-dev',
  storageBucket: 'woofz-dev.firebasestorage.app',
  messagingSenderId: '951503900193',
  appId: '1:951503900193:web:2324a8048966aa76de1d60',
  measurementId: 'G-MJ1T87TVSC',
}

export const firebaseConfigPROD = {
  apiKey: 'AIzaSyBdj0hED68auzR_7dTiUVSHcJBPVEEFOTU',
  authDomain: 'woofz-3c4c1.firebaseapp.com',
  databaseURL: 'https://woofz-3c4c1.firebaseio.com',
  projectId: 'woofz-3c4c1',
  storageBucket: 'woofz-3c4c1.firebasestorage.app',
  messagingSenderId: '735987244362',
  appId: '1:735987244362:web:4f4730849e0311884fb5b3',
  measurementId: 'G-SM09EGLHZM',
}
