import { AnyAction } from 'redux'
import { ISubscription } from 'models/variant.model'
import { ISubscription as ISubscriptionV2 } from 'models/subscriptions.model'
import { TPaymentOptions } from '../types'

import {
  RESET_3D_SECURE_IFRAME_URL,
  SET_3D_SECURE_IFRAME_URL,
  SET_PAYMENT_CLIENT_SECRET,
  SET_SUBSCRIPTION_ID,
  SET_TRIAL_PERIOD_DAYS,
  SET_IS_PAYMENT_FLOWS_SHOWN,
  SET_CANCEL_OFFER,
  SET_SUBSCRIPTION,
  SET_PAYMENT_SYSTEM,
  SET_PAYMENT_OPTIONS,
} from './actions'

export interface IPaymentState {
  selectedSubscription: ISubscription | null
  selectedSubscriptionV2: ISubscriptionV2 | null
  cancelOffer: ISubscriptionV2 | null
  threeDSecureIframeURL: string
  paymentClientSecret: string
  trialPeriodDays: number
  subscriptionId: string
  isPaymentFlowsShown: boolean
  paymentMethod: string
  paymentOptions: TPaymentOptions | null
}

const initState: IPaymentState = {
  selectedSubscription: null,
  selectedSubscriptionV2: null,
  cancelOffer: null,
  threeDSecureIframeURL: '',
  paymentClientSecret: '',
  trialPeriodDays: 0,
  subscriptionId: '',
  isPaymentFlowsShown: false,
  paymentMethod: '',
  paymentOptions: null,
}

export function paymentReducer(
  state: IPaymentState = initState,
  { type, payload = null }: AnyAction,
): IPaymentState {
  switch (type) {
    case SET_SUBSCRIPTION: {
      return {
        ...state,
        selectedSubscriptionV2: payload,
      }
    }
    case SET_CANCEL_OFFER: {
      return {
        ...state,
        cancelOffer: payload,
      }
    }
    case SET_3D_SECURE_IFRAME_URL: {
      return {
        ...state,
        threeDSecureIframeURL: payload,
      }
    }
    case RESET_3D_SECURE_IFRAME_URL: {
      return {
        ...state,
        threeDSecureIframeURL: '',
      }
    }
    case SET_PAYMENT_CLIENT_SECRET: {
      return {
        ...state,
        paymentClientSecret: payload,
      }
    }
    case SET_TRIAL_PERIOD_DAYS: {
      return {
        ...state,
        trialPeriodDays: payload,
      }
    }
    case SET_SUBSCRIPTION_ID: {
      return {
        ...state,
        subscriptionId: payload,
      }
    }
    case SET_IS_PAYMENT_FLOWS_SHOWN: {
      return {
        ...state,
        isPaymentFlowsShown: payload,
      }
    }
    case SET_PAYMENT_SYSTEM: {
      return {
        ...state,
        paymentMethod: payload,
      }
    }
    case SET_PAYMENT_OPTIONS: {
      return {
        ...state,
        paymentOptions: payload,
      }
    }
    default:
      return state
  }
}
