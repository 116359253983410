import { createProductName } from 'helpers/createProductName'
import { TimeInterval } from 'root-constants'
import { ISubscription } from 'models/subscriptions.model'
import { getCurrentEnv } from 'helpers/getCurrentEnv'

export const enum GoogleAnalyticsAction {
  EVENT = 'event',
}

export const enum GoogleAnalyticsEvent {
  PAGE_VIEW = 'page_view',
  ADD_TO_CART = 'add_to_cart',
  PURCHASE = 'purchase',
  FIRST_PAGE_COMPLETED = 'first_page_completed',
}

export const enum GoogleAnalyticsEventCategory {
  TRANSITION = 'transition',
}

class GoogleAnalyticsService {
  logPageView(location: string): void {
    window.dataLayer &&
      window.dataLayer.push({
        event: GoogleAnalyticsEvent.PAGE_VIEW,
        page_path: `/${location}`,
      })
  }

  logAddingToCart({ mainPrices }: ISubscription): void {
    const { fullPrice, periodName, periodQuantity } = mainPrices
    const currentEnv = getCurrentEnv()

    window.dataLayer &&
      window.dataLayer.push({
        event: GoogleAnalyticsEvent.ADD_TO_CART,
        items: [
          {
            id: createProductName({
              periodName,
              periodQuantity,
              price: fullPrice,
            }),
            price: fullPrice,
          },
        ],
      })

    if (window.uetq && currentEnv === 'prod') {
      window.uetq = window.uetq || []
      window.uetq.push('event', 'add_to_cart', {})
    }
  }

  logPurchaseCompleted({
    subscriptionId,
    price,
    periodName,
    periodQuantity,
    currency = 'USD',
  }: {
    subscriptionId: string
    price: number
    periodName: TimeInterval | null
    periodQuantity: number | null
    currency?: string
  }): void {
    window.dataLayer &&
      window.dataLayer.push({
        event: GoogleAnalyticsEvent.PURCHASE,
        transaction_id: subscriptionId,
        value: price,
        currency,
        items: [
          {
            id: createProductName({ periodName, periodQuantity, price }),
            price,
          },
        ],
      })
  }

  logFirstPageCompleted(): void {
    window.dataLayer &&
      window.dataLayer.push({
        event: GoogleAnalyticsEvent.FIRST_PAGE_COMPLETED,
        event_category: GoogleAnalyticsEventCategory.TRANSITION,
      })
  }
}

export const googleAnalyticsLogger = new GoogleAnalyticsService()
