import { getCurrentEnv } from 'helpers/getCurrentEnv'
import { CurrentEnvironment } from 'root-constants'
import {
  firebaseConfigDEV,
  firebaseConfigPROD,
} from 'helpers/constants/firebase-config'

export const getFirebaseConfig = () => {
  const env = getCurrentEnv()

  return env === CurrentEnvironment.PROD
    ? firebaseConfigPROD
    : firebaseConfigDEV
}
