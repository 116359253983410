/* eslint-disable max-lines */
import {
  breedsApiService,
  configApi,
  paymentApi,
  quizApi,
  subscriptionsApi,
  variantsApi,
} from 'api'
import firebase from 'firebase/app'
import { getConfigFromConfigRaw } from 'helpers/getConfigFromConfigRaw'
import { getVariantFromRawVariant } from 'helpers/getVariantFromRawVariant'
import {
  ICalendlyInfo,
  IDogBreed,
  TAnswer,
  TAnswers,
  TQuizAnswer,
} from 'models/common.model'
import { IConfig } from 'models/config.model'
import {
  IAction,
  IAppState,
  TAppActionThunk,
  TAppDispatchThunk,
} from 'models/store.model'
import { IVariant } from 'models/variant.model'
import { AnyAction } from 'redux'
import { eventLogger } from 'services/eventLogger.service'
import { PageId } from 'src/constants/pages'
import { selectUUID } from 'root-redux/selects/user'
import {
  selectAppName,
  selectCurrentVariantCohort,
  selectFirebaseConfigFile,
} from 'root-redux/selects/common'
import { getPaymentConfigFromRawData } from 'helpers/getPaymentConfigFromRawData'
import { IPaymentConfig } from 'models/payment.model'
import { getSubscriptionListFromRawData } from 'helpers/getSubscriptionListFromRawData'
import { ISubscription } from 'models/subscriptions.model'
import { FLOW, PLAN_TYPES, PlatformOS } from 'root-constants'
import { setSubscriptionsPlansWasLoadedAction } from 'modules/payment/redux/actions'
import { getBreedsFromBreedsRaw } from 'helpers/getBreedsFromRawBreeds'
import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'

const MODULE_NAME = 'COMMON'

// actions types
export const START_FETCHING = `${MODULE_NAME}/START_FETCHING`
export const STOP_FETCHING = `${MODULE_NAME}/STOP_FETCHING`
export const SET_ERROR = `${MODULE_NAME}/SET_ERROR`
export const RESET_ERROR = `${MODULE_NAME}/RESET_ERROR`
export const GET_VARIANT = `${MODULE_NAME}/GET_VARIANT`
export const SET_VARIANT = `${MODULE_NAME}/SET_VARIANT`
export const SET_CONFIG = `${MODULE_NAME}/SET_CONFIG`
export const SET_ANSWERS = `${MODULE_NAME}/SET_ANSWERS`
export const SEND_ANSWER = `${MODULE_NAME}/SEND_ANSWER`
export const GET_PAYMENT_CONFIG = `${MODULE_NAME}/GET_PAYMENT_CONFIG`
export const SET_PAYMENT_CONFIG = `${MODULE_NAME}/SET_PAYMENT_CONFIG`
export const GET_SUBSCRIPTIONS_PLANS = `${MODULE_NAME}/GET_SUBSCRIPTIONS_PLANS`
export const SET_ONBOARDING_ANSWERS = `${MODULE_NAME}/SET_ONBOARDING_ANSWERS`
export const SET_SUBSCRIPTION_PLANS = `${MODULE_NAME}/SET_SUBSCRIPTION_PLANS`
export const RESET_ANSWERS = `${MODULE_NAME}/RESET_ANSWERS`
export const SET_IS_PAID_TRIAL_MODAL_SHOWN = `${MODULE_NAME}/SET_IS_PAID_TRIAL_MODAL_SHOWN`
export const SET_OPTIMIZE_VARIANT_ID = `${MODULE_NAME}/SET_OPTIMIZE_VARIANT_ID`
export const SET_IS_CANCEL_OFFER_APPLIED = `${MODULE_NAME}/SET_IS_CANCEL_OFFER_APPLIED`
export const SET_IS_CANCEL_OFFER_NOT_APPLIED = `${MODULE_NAME}/SET_IS_CANCEL_OFFER_NOT_APPLIED`
export const SET_LANGUAGE = `${MODULE_NAME}/SET_LANGUAGE`
export const SET_FIREBASE_REMOTE_CONFIG = `${MODULE_NAME}/SET_FIREBASE_REMOTE_CONFIG`
export const SET_IS_UPSELL_PAID = `${MODULE_NAME}/SET_IS_UPSELL_PAID`
export const SET_IS_LOGGER_INITIALIZED = `${MODULE_NAME}/SET_IS_LOGGER_INITIALIZED`
export const SET_CALENDLY_INFO = `${MODULE_NAME}/SET_CALENDLY_INFO`
export const SET_APP_NAME = `${MODULE_NAME}/SET_APP_NAME`
export const SET_WEBAPP_REDIRECT_FLAG = `${MODULE_NAME}/SET_WEBAPP_REDIRECT_FLAG`
export const GET_BREEDS = `${MODULE_NAME}/GET_BREEDS`
export const SET_BREEDS = `${MODULE_NAME}/SET_BREEDS`

// actions handlers
export function startFetching(action: string): IAction<string> {
  return {
    type: START_FETCHING,
    payload: action,
  }
}

export function setWebappRedirectFlag(payload: boolean): IAction<boolean> {
  return {
    type: SET_WEBAPP_REDIRECT_FLAG,
    payload,
  }
}

export function stopFetching(actionToStop: string): any {
  return (dispatch: TAppDispatchThunk<string[]>, getState: () => IAppState) => {
    const runningActions = getState().common.actionList
    const fetchList = runningActions.filter(
      (action: string) => action && action !== actionToStop,
    )

    dispatch({
      type: STOP_FETCHING,
      payload: fetchList,
    })
  }
}

export function setErrorAction<T>(error: T): IAction<T> {
  return {
    type: SET_ERROR,
    payload: error,
  }
}

export function setCalendlyAction(payload): IAction<ICalendlyInfo> {
  return {
    type: SET_CALENDLY_INFO,
    payload,
  }
}

export function resetErrorAction(): IAction<never> {
  return {
    type: RESET_ERROR,
  }
}

export function setVariantAction(payload: IVariant): IAction<IVariant> {
  return {
    type: SET_VARIANT,
    payload,
  }
}

export function setIsCancelOfferAppliedAction(
  payload: boolean,
): IAction<boolean> {
  return {
    type: SET_IS_CANCEL_OFFER_APPLIED,
    payload,
  }
}

export function setIsCancelOfferNotAppliedAction(
  payload: boolean,
): IAction<boolean> {
  return {
    type: SET_IS_CANCEL_OFFER_NOT_APPLIED,
    payload,
  }
}

export function setIsUpsellPaidAction(payload: boolean): IAction<boolean> {
  return {
    type: SET_IS_UPSELL_PAID,
    payload,
  }
}

export function getVariantAction({
  cohort,
  appName,
}: {
  cohort: string
  appName: string
}): TAppActionThunk<any> {
  return async (dispatch) => {
    dispatch(startFetching(GET_VARIANT))

    const response = await variantsApi.getVariant({ cohort, appName })

    if (response.success && response.data) {
      const variant = getVariantFromRawVariant(response.data.variant)

      dispatch(setVariantAction(variant))
    }

    dispatch(stopFetching(GET_VARIANT))
  }
}

export function setConfigAction(payload: IConfig): IAction<IConfig> {
  return {
    type: SET_CONFIG,
    payload,
  }
}

export function getConfigAction(appName: string): TAppActionThunk<any> {
  return async (dispatch, getState) => {
    const state = getState()
    const {
      webappRedirectForAndroid,
      webappRedirectForIOS,
    } = selectFirebaseConfigFile(state)

    const isAndroid = getMobileOperatingSystem() === PlatformOS.ANDROID
    const searchParams = new URLSearchParams(document.location.search)
    const isWebAppRedirect = searchParams.get('webapp_redirect') === 'true'
    const isWebAppFunnel =
      isWebAppRedirect ||
      (isAndroid && webappRedirectForAndroid) ||
      (!isAndroid && webappRedirectForIOS)
    const giaFlow = isWebAppFunnel ? FLOW.WEB : FLOW.W2W

    dispatch(startFetching(GET_VARIANT))
    dispatch(setWebappRedirectFlag(isWebAppFunnel))

    const response = await configApi.getConfig(appName, giaFlow)

    if (response.success && response.data) {
      const config = getConfigFromConfigRaw(response.data.config)
      dispatch(setConfigAction(config))
    }

    dispatch(stopFetching(GET_VARIANT))
  }
}

export function setPaymentConfigAction(
  payload: IPaymentConfig,
): IAction<IPaymentConfig> {
  return {
    type: SET_PAYMENT_CONFIG,
    payload,
  }
}

export function getPaymentConfigAction(): TAppActionThunk<any> {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)
    const cohort = selectCurrentVariantCohort(state)
    const appName = selectAppName(state)

    dispatch(startFetching(GET_PAYMENT_CONFIG))

    const response = await paymentApi.getPaymentConfig({
      uuid,
      cohort,
      appName,
    })

    if (response.success && response.data) {
      const config = getPaymentConfigFromRawData(response.data)
      eventLogger.updateAmplitudeUserProperties({
        stripe_account_id: config.stripe.accountId,
        stripe_account_name: config.stripe.accountName,
      })
      dispatch(setPaymentConfigAction(config))
    }

    dispatch(stopFetching(GET_PAYMENT_CONFIG))
  }
}

export function setSubscriptionListAction(
  payload: ISubscription[],
): IAction<ISubscription[]> {
  return {
    type: SET_SUBSCRIPTION_PLANS,
    payload,
  }
}

export function getSubscriptionPlans(
  type: PLAN_TYPES,
  tags?: string[],
): TAppActionThunk<any> {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)
    const cohort = selectCurrentVariantCohort(state)
    const appName = selectAppName(state)

    dispatch(startFetching(GET_SUBSCRIPTIONS_PLANS))

    const response = await subscriptionsApi.getSubscriptionsList({
      uuid,
      cohort,
      appName,
      type,
      tags,
    })

    if (response.success && response.data) {
      const config = getSubscriptionListFromRawData(response.data.plans)
      dispatch(setSubscriptionListAction(config))
    } else {
      dispatch(setSubscriptionsPlansWasLoadedAction(false))
    }

    dispatch(stopFetching(GET_SUBSCRIPTIONS_PLANS))
  }
}

export function setAnswersAction({
  question,
  answers,
  pageNumber,
  pageName,
  customProperties,
}: {
  question: string
  answers: TAnswer
  pageNumber: number
  pageName: PageId
  customProperties?: Record<string, any>
}): AnyAction {
  eventLogger.logQuestion({
    question: question || pageName,
    answers,
    pageNumber,
    customProperties,
  })

  return {
    type: SET_ANSWERS,
    payload: { [pageName]: answers },
  }
}

export function setAnswersWithQuizAction({
  question,
  answers,
  pageNumber,
  pageName,
  customProperties,
  quiz,
}: {
  question: string
  answers: TAnswer
  pageNumber: number
  pageName: PageId
  customProperties?: Record<string, any>
  quiz: TQuizAnswer
}): AnyAction {
  eventLogger.logQuestion({
    question: question || pageName,
    answers,
    pageNumber,
    customProperties,
  })

  return {
    type: SET_ANSWERS,
    payload: quiz,
  }
}

export const saveUserAnswer = ({
  question,
  answers,
  pageNumber,
  pageName,
  quiz,
  answersToStore,
}: {
  question: string
  answers: TAnswer
  pageNumber: number
  pageName: PageId
  quiz?: TQuizAnswer
  answersToStore?: TAnswer
}): any => async (
  dispatch: TAppDispatchThunk<any>,
  getState: () => IAppState,
): Promise<void> => {
  const state = getState()
  const uuid = selectUUID(state)
  const appName = selectAppName(state)

  dispatch(startFetching(SEND_ANSWER))

  const response = quiz
    ? await quizApi.sendAnswers({
        uuid,
        question: pageName,
        answers,
        appName,
        quiz,
      })
    : await quizApi.sendAnswer({
        uuid,
        question: pageName,
        answers: answersToStore || answers,
        appName,
      })
  quiz
    ? dispatch(
        setAnswersWithQuizAction({
          question,
          answers,
          pageNumber,
          pageName,
          quiz,
        }),
      )
    : dispatch(
        setAnswersAction({
          question,
          answers,
          pageNumber,
          pageName,
        }),
      )

  if (!response.success) {
    dispatch(
      setErrorAction(
        'Something went wrong. Please check the address and try again',
      ),
    )
  }

  dispatch(stopFetching(SEND_ANSWER))
}

export function setBreeds(
  breeds: Record<string, IDogBreed[]>,
): IAction<Record<string, IDogBreed[]>> {
  return {
    type: SET_BREEDS,
    payload: breeds,
  }
}

export const getBreeds = (locale: string): TAppActionThunk<any> => async (
  dispatch: TAppDispatchThunk<any>,
): Promise<void> => {
  try {
    dispatch(startFetching(GET_BREEDS))

    const response = await breedsApiService.getBreeds(locale)
    const data = await response.json()
    const { breeds, popularBreeds } = getBreedsFromBreedsRaw(data.breeds)

    dispatch(setBreeds({ breeds, popularBreeds }))
  } catch (error) {
    dispatch(stopFetching(GET_BREEDS))
  } finally {
    dispatch(stopFetching(GET_BREEDS))
  }
}

export function setAllAnswersAction(answers: TAnswers): AnyAction {
  return {
    type: SET_ONBOARDING_ANSWERS,
    payload: answers,
  }
}

export function setOptimizeVariantIdAction(id: string): IAction<string> {
  return {
    type: SET_OPTIMIZE_VARIANT_ID,
    payload: id,
  }
}

export function setLanguageAction(language: string): IAction<string> {
  return {
    type: SET_LANGUAGE,
    payload: language,
  }
}

export function setFirebaseRemoteConfigAction(
  config: firebase.remoteConfig.RemoteConfig | null,
): IAction<firebase.remoteConfig.RemoteConfig | null> {
  return {
    type: SET_FIREBASE_REMOTE_CONFIG,
    payload: config,
  }
}

export function setIsPaidTrialModalShownAction(
  payload: boolean,
): IAction<boolean> {
  return {
    type: SET_IS_PAID_TRIAL_MODAL_SHOWN,
    payload,
  }
}

export function setIsLoggerInitialized(payload: boolean): IAction<boolean> {
  return {
    type: SET_IS_LOGGER_INITIALIZED,
    payload,
  }
}

export function setAppName(payload: string): IAction<string> {
  return {
    type: SET_APP_NAME,
    payload,
  }
}
