import { initReactI18next } from 'react-i18next'
import en from 'assets/i18n/en.json'
import fr from 'assets/i18n/fr.json'
import es from 'assets/i18n/es.json'
import pt from 'assets/i18n/pt.json'
import de from 'assets/i18n/de.json'
import it from 'assets/i18n/it.json'
import kr from 'assets/i18n/kr.json'
import jp from 'assets/i18n/jp.json'
import pl from 'assets/i18n/pl.json'
import tr from 'assets/i18n/tr.json'
import ro from 'assets/i18n/ro.json'
import i18n from 'i18next'

i18n.use(initReactI18next).init({
  resources: {
    en,
    es,
    fr,
    pt,
    de,
    it,
    kr,
    jp,
    pl,
    tr,
    ro,
  },
  defaultNS: 'common',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
})
