import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getConfigAction, setAppName } from 'root-redux/actions/common'
import { getAppName } from 'helpers/getAppName'
import { selectIsFirebaseCongifLoaded } from 'root-redux/selects/common'

export const useInitAppConfig = (): void => {
  const dispatch = useDispatch()
  const { search } = window.location
  const urlParams = new URLSearchParams(search)
  const currentCohort = urlParams.get('cohort') || ''
  const appName = useMemo(() => getAppName(currentCohort), [currentCohort])
  const isFirebaseConfigLoaded = useSelector(selectIsFirebaseCongifLoaded)

  useEffect(() => {
    dispatch(setAppName(appName))

    if (isFirebaseConfigLoaded) {
      dispatch(getConfigAction(appName))
    }
  }, [dispatch, appName, isFirebaseConfigLoaded])
}
