/* eslint-disable max-lines */
export const BREED_NAMES = [
  'Afghan Hound',
  'Airedale Terrier',
  'Akita',
  'Alaskan Malamute',
  'American Eskimo Dog',
  'American Foxhound',
  'American Pit Bull Terrier',
  'American Staffordshire Terrier',
  'American Water Spaniel',
  'Anatolian Shepherd',
  'Australian Cattle Dog',
  'Australian Kelpie',
  'Australian Shepherd',
  'Australian Terrier',
  'Affenpinscher',
  'Basenji',
  'Basset Hound',
  'Beagle',
  'Bearded Collie',
  'Bedlington Terrier',
  'Belgian Malinois',
  'Belgian Sheepdog',
  'Belgian Tervuren',
  'Bernese Mountain Dog',
  'Bichon Frise',
  'Black and Tan Coonhound',
  'Bloodhound',
  'Border Collie',
  'Border Terrier',
  'Borzol',
  'Boston Terrier',
  'Bouvier des Flandres',
  'Boxer',
  'Briard',
  'Brittany',
  'Brussels Griffon',
  'Bull Terrier',
  'Bulldog',
  'Bullmastiff',
  'Cairn Terrier',
  'Canaan Dog',
  'Cardigan Welsh Corgi',
  'Cavalier King Charles Spaniel',
  'Chesapeake Bay Retriever',
  'Chihuahua',
  'Chinese Crested',
  'Chinese Shar - Pei',
  'Chow Chow',
  'Clumber Spaniel',
  'Cocker Spaniel',
  'Collie',
  'Curly - Coated Retriever',
  'Dachshund',
  'Dalmatian',
  'Dandle Dinmont Terrier',
  'Doberman Pinscher',
  'English Cocker Spaniel',
  'English Foxhound',
  'English Setter',
  'English Springer Spaniel',
  'English Toy Spaniel',
  'Field Spaniel',
  'Finnish Spitz',
  'Flat - Coated Retriever',
  'French Bulldog',
  'German Pinscher',
  'German Shepherd',
  'German Shorthaired Pointer',
  'German Wirehaired Pointer',
  'Giant Schnauzer',
  'Golden Retriever',
  'Gordon Setter',
  'Great Dane',
  'Great Pyrenees',
  'Greater Swiss Mountain Dog',
  'Greyhound',
  'Harrier',
  'Havanese',
  'Ibizan Hound',
  'Irish Setter',
  'Irish Terrier',
  'Irish Water Spaniel',
  'Irish Wolfhound',
  'Italian Greyhound',
  'Jack Russell Terrier',
  'Japanese Chin',
  'Keeshond',
  'Kerry Blue Terrier',
  'Komondor',
  'Kuvasz',
  'Labrador Retriever',
  'Lakeland Terrier',
  'Lhasa Apso',
  'Lowchen',
  'Maltese',
  'Manchester Terrier',
  'Mastiff',
  'Miniature Bull Terrier',
  'Miniature Pinscher',
  'Miniature Schnauzer',
  'Mixed Breed',
  'Newfoundland',
  'Norfolk Terrier',
  'Norwegian Elkhound',
  'Norwich Terrier',
  'Old English Sheepdog',
  'Other Breed',
  'Otterhound',
  'Papillon',
  'Pekingese',
  'Pembroke Welsh Corgi',
  'Petits Bassets Griffons Vendeen',
  'Pharaoh Hound',
  'Plott',
  'Pointer',
  'Polish Lowland Sheepdog',
  'Pomeranian',
  'Poodle',
  'Portuguese Water Dog',
  'Pug',
  'Puli',
  'Rat Terrier',
  'Rhodesian Ridgeback',
  'Rottweiler',
  'Saluki',
  'Samoyed',
  'Schipperke',
  'Scottish Deerhound',
  'Scottish Terrier',
  'Sealyham Terrier',
  'Sheltie',
  'Shetland Sheepdog',
  'Shiba Inu',
  'Shih Tzu',
  'Siberian Husky',
  'Silky Terrier',
  'Skye Terrier',
  'Smooth Fox Terrier',
  'Soft Coated Wheaten Terrier',
  'Spinone Italiano',
  'St.Bernard',
  'Staffordshire Bull Terrier',
  'Standard Schnauzer',
  'Sussex Spaniel',
  'Tibetan Spaniel',
  'Tibetan Terrier',
  'Toy Fox Terrier',
  'Vizsla',
  'Weimaraner',
  'Welsh Springer Spaniel',
  'Welsh Terrier',
  'West Highland White Terrier',
  'Whippet',
  'Wire Fox Terrier',
  'Wirehaired Pointing Griffon',
  'Yorkshire Terrier',
]

export const POPULAR_BREEDS = [
  'Mixed Breed',
  'Golden Retriever',
  'Labrador Retriever',
  'German Shepherd',
  'American Pit Bull Terrier',
  'French Bulldog',
  'Chihuahua',
  'Border Collie',
  'Siberian Husky',
  'Yorkshire Terrier',
  'Shih Tzu',
  'Poodle',
  'Beagle',
] as const

export const POPULAR_BREEDS_KEYS = [
  'americanPitBull',
  'beagle',
  'borderCollie',
  'chihuahua',
  'frenchBulldog',
  'germanShepherd',
  'goldenRetriever',
  'labradorRetriever',
  'mixedBreed',
  'poodle',
  'shihTzu',
  'siberianHusky',
  'yorkshireTerrier',
  'welshCorgi',
]

export const BREEDS_MAP = {
  americanPitBull: [
    'American Pit Bull Terrier',
    'Pit Bull Terrier Americano',
    'Pit bull terrier americano',
    '아메리칸 핏불 테리어',
    'アメリカン・ピット・ブル・テリア',
    'Amerykański Pit Bull Terrier',
    'Amerikan Pit Bull Terrier',
  ],
  beagle: ['Beagle', '비글', 'ビーグル'],
  borderCollie: [
    'Border Collie',
    'Border collie',
    '보더 콜리',
    'ボーダーコリー',
  ],
  chihuahua: ['Chihuahua', '치와와', 'チワワ'],
  frenchBulldog: [
    'French Bulldog',
    'Französische Bulldogge',
    'Bouledogue français',
    'Buldogue Francês',
    'Bulldog francés',
    'Bulldog francese',
    '프랑스 불독',
    'フレンチ・ブルドッグ',
    'Buldog francuski',
    'Fransız Bulldog',
  ],
  germanShepherd: [
    'German Shepherd',
    'Deutscher Schäferhund',
    'Berger allemand',
    'Pastor-alemão',
    'Pastor alemán',
    'Pastore Tedesco',
    '독일 셰퍼드',
    'ジャーマンシェパード',
    'Owczarek niemiecki',
    'Alman Çoban Köpeği',
  ],
  goldenRetriever: [
    'Golden Retriever',
    'Golden retriever',
    '골든 리트리버',
    'ゴールデンレトリバー',
  ],
  labradorRetriever: [
    'Labrador Retriever',
    'Labrador',
    'Labrador retriever',
    '래브라도 리트리버',
    'ラブラドール・レトリバー',
  ],
  mixedBreed: [
    'Mixed Breed',
    'Mischling',
    'Race croisée',
    'Raça mista',
    'Raza mixta',
    'Misto',
    '혼합 품종',
    '雑種',
    'Rasy mieszane',
    'Melez',
  ],
  poodle: [
    'Poodle',
    'Pudel',
    'Caniche',
    'Barboncino',
    '푸들',
    'プードル',
    'Pudel',
    'Kaniş',
  ],
  shihTzu: ['Shih Tzu', 'Shih tzu', '시츄', 'シーズー'],
  siberianHusky: [
    'Siberian Husky',
    'Husky de Sibérie',
    'Husky Siberiano',
    'Husky siberiano',
    '시베리안 허스키',
    'シベリアンハスキー',
    'Husky syberyjski',
    'Sibirya Husky',
  ],
  yorkshireTerrier: [
    'Yorkshire Terrier',
    'Yorkshire terrier',
    '요크셔 테리어',
    'ヨークシャーテリア',
  ],
  welshCorgi: [
    'Pembroke Welsh Corgi',
    'Welsh Corgi Pembroke',
    'Corgi galés de Pembroke',
    '펨브로크 웰시코기',
    'ウェルシュ・コーギー・ペンブローク',
  ],
}

export const POPULAR_BREEDS_LANG = {
  en: [
    'Mixed Breed',
    'Golden Retriever',
    'Labrador Retriever',
    'German Shepherd',
    'American Pit Bull Terrier',
    'French Bulldog',
    'Chihuahua',
    'Border Collie',
    'Siberian Husky',
    'Yorkshire Terrier',
    'Shih Tzu',
    'Poodle',
    'Beagle',
    'Pembroke Welsh Corgi',
  ],
  it: [
    'Misto',
    'Golden Retriever',
    'Labrador Retriever',
    'Pastore Tedesco',
    'American Pit Bull Terrier',
    'Bulldog francese',
    'Chihuahua',
    'Border Collie',
    'Husky siberiano',
    'Yorkshire Terrier',
    'Shih Tzu',
    'Barboncino',
    'Beagle',
    'Pembroke Welsh Corgi',
  ],
  de: [
    'Mischling',
    'Golden Retriever',
    'Labrador Retriever',
    'Deutscher Schäferhund',
    'American Pit Bull Terrier',
    'Französische Bulldogge',
    'Chihuahua',
    'Border Collie',
    'Siberian Husky',
    'Yorkshire Terrier',
    'Shih Tzu',
    'Pudel',
    'Beagle',
    'Welsh Corgi Pembroke',
  ],
  fr: [
    'Race croisée',
    'Golden Retriever',
    'Labrador',
    'Berger allemand',
    'American Pit Bull Terrier',
    'Bouledogue français',
    'French Bulldog',
    'Chihuahua',
    'Border Collie',
    'Husky de Sibérie',
    'Yorkshire Terrier',
    'Shih Tzu',
    'Caniche',
    'Beagle',
    'Welsh Corgi Pembroke',
  ],
  pt: [
    'Raça mista',
    'Golden Retriever',
    'Labrador Retriever',
    'Pastor-alemão',
    'Pit Bull Terrier Americano',
    'Buldogue Francês',
    'Chihuahua',
    'Border Collie',
    'Husky Siberiano',
    'Yorkshire Terrier',
    'Shih Tzu',
    'Poodle',
    'Beagle',
    'Welsh Corgi Pembroke',
  ],
  es: [
    'Raza mixta',
    'Golden retriever',
    'Labrador retriever',
    'Pastor alemán',
    'Pit bull terrier americano',
    'Bulldog francés',
    'Chihuahua',
    'Border collie',
    'Husky siberiano',
    'Yorkshire terrier',
    'Shih tzu',
    'Caniche',
    'Beagle',
    'Corgi galés de Pembroke',
  ],
  kr: [
    '혼합 품종',
    '골든 리트리버',
    '래브라도 리트리버',
    '독일 셰퍼드',
    '아메리칸 핏불 테리어',
    '프랑스 불독',
    '치와와',
    '보더 콜리',
    '시베리안 허스키',
    '요크셔 테리어',
    '시츄',
    '푸들',
    '비글',
    '펨브로크 웰시코기',
  ],
  jp: [
    '雑種',
    'ゴールデンレトリバー',
    'ラブラドール・レトリバー',
    'ジャーマンシェパード',
    'アメリカン・ピット・ブル・テリア',
    'フレンチ・ブルドッグ',
    'チワワ',
    'ボーダーコリー',
    'シベリアンハスキー',
    'ヨークシャーテリア',
    'シーズー',
    'プードル',
    'ビーグル',
    'ウェルシュ・コーギー・ペンブローク',
  ],
  pl: [
    'Rasy mieszane',
    'Golden Retriever',
    'Labrador Retriever',
    'Owczarek niemiecki',
    'Amerykański Pit Bull Terrier',
    'Buldog francuski',
    'Chihuahua',
    'Border Collie',
    'Husky syberyjski',
    'Yorkshire Terrier',
    'Shih Tzu',
    'Pudel',
    'Beagle',
    'Pembroke Welsh Corgi',
  ],
  tr: [
    'Melez',
    'Golden Retriever',
    'Labrador Retriever',
    'Alman Çoban Köpeği',
    'American Pit Bull Terrier',
    'Fransız Bulldog',
    'Chihuahua',
    'Border Collie',
    'Sibirya Husky',
    'Yorkshire Terrier',
    'Shih Tzu',
    'Kaniş',
    'Beagle',
    'Pembroke Welsh Corgi',
  ],
  ro: [
    'Mixed Breed',
    'Golden Retriever',
    'Labrador Retriever',
    'German Shepherd',
    'American Pit Bull Terrier',
    'French Bulldog',
    'Chihuahua',
    'Border Collie',
    'Siberian Husky',
    'Yorkshire Terrier',
    'Shih Tzu',
    'Poodle',
    'Beagle',
  ],
}

export const BREED_NAMES_LANG = {
  en: [
    'Afghan Hound',
    'Airedale Terrier',
    'Akita',
    'Alaskan Malamute',
    'American Eskimo Dog',
    'American Foxhound',
    'American Pit Bull Terrier',
    'American Staffordshire Terrier',
    'American Water Spaniel',
    'Anatolian Shepherd',
    'Australian Cattle Dog',
    'Australian Kelpie',
    'Australian Shepherd',
    'Australian Terrier',
    'Affenpinscher',
    'Basenji',
    'Basset Hound',
    'Beagle',
    'Bearded Collie',
    'Bedlington Terrier',
    'Belgian Malinois',
    'Belgian Sheepdog',
    'Belgian Tervuren',
    'Bernese Mountain Dog',
    'Bichon Frise',
    'Black and Tan Coonhound',
    'Bloodhound',
    'Border Collie',
    'Border Terrier',
    'Borzol',
    'Boston Terrier',
    'Bouvier des Flandres',
    'Boxer',
    'Briard',
    'Brittany',
    'Brussels Griffon',
    'Bull Terrier',
    'Bulldog',
    'Bullmastiff',
    'Cairn Terrier',
    'Canaan Dog',
    'Cardigan Welsh Corgi',
    'Cavalier King Charles Spaniel',
    'Chesapeake Bay Retriever',
    'Chihuahua',
    'Chinese Crested',
    'Chinese Shar - Pei',
    'Chow Chow',
    'Clumber Spaniel',
    'Cocker Spaniel',
    'Collie',
    'Curly - Coated Retriever',
    'Dachshund',
    'Dalmatian',
    'Dandle Dinmont Terrier',
    'Doberman Pinscher',
    'English Cocker Spaniel',
    'English Foxhound',
    'English Setter',
    'English Springer Spaniel',
    'English Toy Spaniel',
    'Field Spaniel',
    'Finnish Spitz',
    'Flat - Coated Retriever',
    'French Bulldog',
    'German Pinscher',
    'German Shepherd',
    'German Shorthaired Pointer',
    'German Wirehaired Pointer',
    'Giant Schnauzer',
    'Golden Retriever',
    'Gordon Setter',
    'Great Dane',
    'Great Pyrenees',
    'Greater Swiss Mountain Dog',
    'Greyhound',
    'Harrier',
    'Havanese',
    'Ibizan Hound',
    'Irish Setter',
    'Irish Terrier',
    'Irish Water Spaniel',
    'Irish Wolfhound',
    'Italian Greyhound',
    'Jack Russell Terrier',
    'Japanese Chin',
    'Keeshond',
    'Kerry Blue Terrier',
    'Komondor',
    'Kuvasz',
    'Labrador Retriever',
    'Lakeland Terrier',
    'Lhasa Apso',
    'Lowchen',
    'Maltese',
    'Manchester Terrier',
    'Mastiff',
    'Miniature Bull Terrier',
    'Miniature Pinscher',
    'Miniature Schnauzer',
    'Mixed Breed',
    'Newfoundland',
    'Norfolk Terrier',
    'Norwegian Elkhound',
    'Norwich Terrier',
    'Old English Sheepdog',
    'Other Breed',
    'Otterhound',
    'Papillon',
    'Pekingese',
    'Pembroke Welsh Corgi',
    'Petits Bassets Griffons Vendeen',
    'Pharaoh Hound',
    'Plott',
    'Pointer',
    'Polish Lowland Sheepdog',
    'Pomeranian',
    'Poodle',
    'Portuguese Water Dog',
    'Pug',
    'Puli',
    'Rat Terrier',
    'Rhodesian Ridgeback',
    'Rottweiler',
    'Saluki',
    'Samoyed',
    'Schipperke',
    'Scottish Deerhound',
    'Scottish Terrier',
    'Sealyham Terrier',
    'Sheltie',
    'Shetland Sheepdog',
    'Shiba Inu',
    'Shih Tzu',
    'Siberian Husky',
    'Silky Terrier',
    'Skye Terrier',
    'Smooth Fox Terrier',
    'Soft Coated Wheaten Terrier',
    'Spinone Italiano',
    'St.Bernard',
    'Staffordshire Bull Terrier',
    'Standard Schnauzer',
    'Sussex Spaniel',
    'Tibetan Spaniel',
    'Tibetan Terrier',
    'Toy Fox Terrier',
    'Vizsla',
    'Weimaraner',
    'Welsh Springer Spaniel',
    'Welsh Terrier',
    'West Highland White Terrier',
    'Whippet',
    'Wire Fox Terrier',
    'Wirehaired Pointing Griffon',
    'Yorkshire Terrier',
  ],
  it: [
    'Mastino Afgano',
    'Airedale Terrier',
    'Akita',
    'Alaskan Malamute',
    'Cane eschimese americano',
    'Foxhound americano',
    'American Pit Bull Terrier',
    'American Staffordshire Terrier',
    'Spaniel d’acqua americano',
    'Anatolian Shepherd',
    'Australian Cattle Dog',
    'Kelpie australiano',
    'Pastore Australiano',
    'Terrier australiano',
    'Affenpinscher',
    'Basenji',
    'Basset Hound',
    'Beagle',
    'Collie barbuto',
    'Bedlington Terrier',
    'Malinois belga',
    'Belgian Sheepdog',
    'Tervuren Belga',
    'Cane da montagna bernese',
    'Bichon Frise',
    'Coonhound nero e marrone',
    'Bloodhound',
    'Border Collie',
    'Border Terrier',
    'Borzol',
    'Boston Terrier',
    'Bouvier Des Flandres',
    'Boxer',
    'Briard',
    'Bretagna',
    'Griffone di Bruxelles',
    'Bull Terrier',
    'Bulldog',
    'Bullmastiff',
    'Cairn Terrier',
    'Cane di Canaan',
    'Cardigan Welsh Corgi',
    'Cavalier King Charles Spaniel',
    'Chesapeake Bay Retriever',
    'Chihuahua',
    'Cane cinese crestato',
    'Chinese Shar - Pei',
    'Chow Chow',
    'Clumber Spaniel',
    'Cocker Spaniel Inglese',
    'Collie',
    'Curly - Coated Retriever',
    'Bassotto',
    'Dalmata',
    'Dandle Dinmont Terrier',
    'Doberman Pinscher',
    'Cocker Spaniel inglese',
    'Foxhound inglese',
    'Setter inglese',
    'Springer Spaniel inglese',
    'English Toy Spaniel',
    'Spaniel da campo',
    'Spitz finlandese',
    'Flat - Coated Retriever',
    'Bulldog francese',
    'Pinscher tedesco',
    'Pastore Tedesco',
    'German Shorthaired Pointer',
    'German Wirehaired Pointer',
    'Schnauzer gigante',
    'Golden Retriever',
    'Gordon Setter',
    'Alano',
    'Grande Pireneo',
    'Greater Swiss Mountain Dog',
    'Levriero',
    'Harrier',
    'Havanese',
    'Ibizan Hound',
    'Irish Setter',
    'Terrier irlandese',
    'Irish Water Spaniel',
    'Mastino irlandese',
    'Levriero italiano',
    'Jack Russell Terrier',
    'Cin Cin giapponese',
    'Keeshond',
    'Kerry Blue Terrier',
    'Komondor',
    'Kuvasz',
    'Labrador Retriever',
    'Lakeland Terrier',
    'Lhasa Apso',
    'Lowchen',
    'Maltese',
    'Manchester Terrier',
    'Mastino',
    'Bull Terrier in miniatura',
    'Pinscher in miniatura',
    'Schnauzer in miniatura',
    'Misto',
    'Terranova',
    'Norfolk Terrier',
    'Elkhound norvegese nero',
    'Norwich Terrier',
    'Vecchio cane da pastore inglese',
    'Other Breed',
    'Levriero',
    'Papillon',
    'Pechinese',
    'Pembroke Welsh Corgi',
    'Petits Bassets Griffons Vendeen',
    'Mastino Faraone',
    'Plott',
    'Pointer',
    'Cane da pastore polacco di pianura',
    'Pomerania',
    'Barboncino',
    'Cane d’acqua portoghese',
    'Carlino',
    'Puli',
    'Rat Terrier',
    'Rhodesian Ridgeback',
    'Rottweiler',
    'Saluki',
    'Samoiedo',
    'Schipperke',
    'Scottish Deerhound',
    'Terrier scozzese',
    'Sealyham Terrier',
    'Sheltie',
    'Shetland Sheepdog',
    'Shiba Inu',
    'Shih Tzu',
    'Husky siberiano',
    'Silky Terrier',
    'Skye Terrier',
    'Fox Terrier liscio',
    'Terrier irlandese a pelo corto',
    'Spinone Italiano',
    'St.Bernard',
    'Staffordshire Bull Terrier',
    'Standard Schnauzer',
    'Sussex Spaniel',
    'Spaniel tibetano',
    'Terrier tibetano',
    'Fox Terrier giocattolo',
    'Vizsla',
    'Weimaraner',
    'Welsh Springer Spaniel',
    'Terrier gallese',
    'West Highland White Terrier',
    'Whippet',
    'Wire Fox Terrier',
    'Wirehaired Pointing Griffon',
    'Yorkshire Terrier',
  ],
  fr: [
    'Affenpinscher',
    'Airedale Terrier',
    'Akita',
    'American Pit Bull Terrier',
    'American Staffordshire Terrie',
    'Anatolian Shepherd',
    'Barzoï ou lévrier russe',
    'Basenji',
    'Basset Hound',
    'Beagle',
    'Bearded Collie',
    'Bedlington Terrier',
    'Belgian Sheepdog',
    'Berger allemand',
    'Berger australien',
    'Berger belge malinois',
    'Berger belge Tervueren',
    'Berger de Brie',
    'Berger des Shetland',
    'Berger polonais de plaine',
    'Bichon Frise',
    'Bichon havanais',
    'Bichon maltais',
    'Bobtail',
    'Border Collie',
    'Border Terrier',
    'Bouledogue français',
    'Bouvier australien',
    'Bouvier bernois',
    'Bouvier des Flandres',
    'Boxer',
    'Braque allemand à poil court',
    'Braque de Weimar',
    'Breton',
    'Bull-terrier du Staffordshire',
    'Bull-terrier miniature',
    'Bull-terrier',
    'Bulldog',
    'Bullmastiff',
    'Cairn Terrier',
    'Caniche',
    'Carlin',
    'Cavalier King Charles',
    'Chien à loutre',
    'Chien chinois à crête',
    'Chien d`arrêt allemand à poil dur',
    'Chien d`eau américain',
    'Chien d`eau irlandais',
    'Chien d`eau portugais',
    'Chien d`élan norvégien noir',
    'Chien de Canaan',
    'Chien de montagne des Pyrénées',
    'Chien de Rhodésie à crête dorsale',
    'Chien de Saint-Hubert',
    'Chien du pharaon',
    'Chien noir et feu pour la chasse au raton laveur',
    'Chien papillon',
    'Chihuahua',
    'Chinese Shar - Pei',
    'Chow-chow',
    'Clumber Spaniel',
    'Cocker anglais',
    'Cocker Spaniel',
    'Collie',
    'Dalmatien',
    'Dandle Dinmont Terrier',
    'Doberman Pinscher',
    'Dogue allemand',
    'English Toy Spaniel',
    'Épagneul japonais',
    'Épagneul tibétain',
    'Esquimau américain',
    'Field Spaniel',
    'Fox-hound américain',
    'Fox-hound anglais',
    'Fox-terrier à poil lisse',
    'Fox-terrier gallois à poil dur',
    'Fox-terrier miniature',
    'Golden Retriever',
    'Grand bouvier suisse',
    'Griffon bruxellois',
    'Harrier',
    'Husky de Sibérie',
    'Irish Setter',
    'Jack Russell',
    'Kelpie australien',
    'Komondor',
    'Kuvasz',
    'Labrador',
    'Lakeland Terrier',
    'Le griffon d’arrêt à poil dur Korthals',
    'Lévrier afghan',
    'Lévrier anglais',
    'Lévrier écossais',
    'Lévrier irlandais',
    'Lévrier italien',
    'Lévrier persan',
    'Lhassa Apso',
    'Loulou de Poméranie',
    'Lowchen',
    'Malamute de l`Alaska',
    'Manchester Terrier',
    'Mastiff',
    'Other Breed',
    'Pékinois',
    'Petit basset griffon vendéen',
    'Pinscher allemand',
    'Pinscher nain',
    'Plott Hound',
    'Podenco d`Ibiza',
    'Pointer anglais',
    'Puli',
    'Race croisée',
    'Rat Terrier',
    'Retriever à poil bouclé',
    'Retriever à poil plat',
    'Retriever de la baie de Chesapeake',
    'Rottweiler',
    'Saint-bernard',
    'Samoyède',
    'Schipperke',
    'Schnauzer géant',
    'Schnauzer nain',
    'Sealyham Terrier',
    'Setter anglais',
    'Setter Gordon',
    'Shiba',
    'Shih Tzu',
    'Skye Terrier',
    'Spinone',
    'Spitz finlandais',
    'Spitz-Loup',
    'Springer anglais',
    'Sussex Spaniel',
    'Teckel',
    'Terre-Neuve',
    'Terrier australien à poil soyeux',
    'Terrier australien',
    'Terrier de Boston',
    'Terrier de Norfolk',
    'Terrier de Norwich',
    'Terrier écossais',
    'Terrier irlandais à poil doux',
    'Terrier irlandais',
    'Terrier Kerry Bluer',
    'Terrier tibétain',
    'Vizsla',
    'Welsh Corgi (Cardigan)',
    'Welsh Corgi Pembroke',
    'Welsh Springer Spaniel',
    'Welsh Terrier',
    'Westie',
    'Whippet',
    'Yorkshire Terrier',
  ],
  de: [
    'Affenpinscher',
    'Afghanischer Windhund',
    'Airedale Terrier',
    'Akita',
    'Alaskan Malamute',
    'American Eskimo Dog',
    'American Pit Bull Terrier',
    'American Staffordshire Terrie',
    'Amerikanischer Foxhound',
    'Amerikanischer Wasserspaniel',
    'Anatolian Shepherd', // не было
    'Australian Cattle Dog',
    'Australian Kelpie',
    'Australian Shepherd',
    'Australian Terrier',
    'Barzoi',
    'Basenji',
    'Basset Hound',
    'Beagle',
    'Bearded Collie',
    'Bedlington Terrier',
    'Belgian Sheepdog', // не было
    'Berner Sennenhund',
    'Bernhardiner',
    'Bichon Frise',
    'Black and Tan Coonhound',
    'Bloodhound',
    'Bobtail',
    'Border Collie',
    'Border Terrier',
    'Boston Terrier',
    'Boxer',
    'Bretone',
    'Briard',
    'Brüsseler Griffon',
    'Bulldogge',
    'Bullmastiff',
    'Bullterrier',
    'Cairn Terrier',
    'Cavalier King Charles Spaniel',
    'Chesapeake Bay Retriever',
    'Chihuahua',
    'Chinese Shar - Pei',
    'Chinesischer Schopfhund',
    'Chow-Chow',
    'Clumber Spaniel',
    'Cocker Spaniel',
    'Collie', // не было
    'Curly Coated Retriever',
    'Dackel',
    'Dalmatiner',
    'Dandle Dinmont Terrier',
    'Deutsch Drahthaar',
    'Deutsch Kurzhaar',
    'Deutsche Dogge',
    'Deutscher Pinscher',
    'Deutscher Schäferhund',
    'Doberman Pinscher', // не было
    'Englischer Cocker Spaniel',
    'Englischer Foxhound',
    'Englischer Pointer',
    'Englischer Setter',
    'English Springer Spaniel',
    'English Toy Spaniel',
    'Field Spaniel',
    'Finnen-Spitz',
    'Flandrischer Treibhund',
    'Flat Coated Retriever',
    'Französische Bulldogge',
    'Französischer Rauhaariger Vorstehhund (Korthals)',
    'Glatthaar-Foxterrierr',
    'Golden Retriever',
    'Gordon Setter',
    'Greyhound',
    'Großer Schweizer Sennenhund',
    'Harrier',
    'Havaneser',
    'Ibiza-Podenco',
    'Irischer Terrier',
    'Irischer Wasserspaniel',
    'Irischer Wolfshund',
    'Irish Setter', // не бфло
    'Italienischer Spinone',
    'Italienisches Windspiel',
    'Jack Russell Terrier',
    'Japan-Chin',
    'Kanaan-Hund',
    'Kerry Blue Terrier',
    'Kleiner Basset Griffon Vendeen',
    'Komondor',
    'Kuvasz',
    'Labrador Retriever',
    'Lakeland Terrier',
    'Lhasa Apso',
    'Löwchen',
    'Malinois',
    'Malteser',
    'Manchester Terrier',
    'Mastiff',
    'Miniature Bull Terrier',
    'Mischling',
    'Mops',
    'Neufundländer',
    'Norfolk Terrier',
    'Norwegischer Elchhund Schwarz',
    'Norwich Terrier',
    'Other Breed', // нету
    'Otterhund',
    'Papillon',
    'Pekingese',
    'Pharaonenhund',
    'Plott Hound',
    'Polnischer Niederungshütehund',
    'Portugiesischer Wasserhund',
    'Pudel',
    'Puli',
    'Pyrenäenberghund',
    'Rat Terrier',
    'Rhodesian Ridgeback',
    'Riesenschnauzer',
    'Rottweiler',
    'Saluki',
    'Samojede',
    'Schipperke',
    'Schottischer Hirschhund',
    'Scottish Terrier',
    'Sealyham Terrier',
    'Sheltie',
    'Shiba',
    'Shih Tzu',
    'Siberian Husky',
    'Silky Terrier',
    'Skye Terrier',
    'Soft Coated Wheaten Terrier',
    'Staffordshire Bullterrier',
    'Sussex Spaniel',
    'Tervueren',
    'Tibet-Terrier',
    'Tibetan Spaniel',
    'Toy Fox Terrier',
    'Vizsla',
    'Weimaraner', // не было
    'Welsh Corgi (Cardigan)',
    'Welsh Corgi Pembroke',
    'Welsh Springer Spaniel',
    'Welsh Terrier',
    'West Highland White Terrier',
    'Whippet',
    'Wire Fox Terrier',
    'Wolfsspitz',
    'Yorkshire Terrier',
    'Zwergpinscher',
    'Zwergschnauzer',
    'Zwergspitz',
  ],
  pt: [
    'Affenpinscher',
    'Airedale Terrier',
    'Akita Inu',
    'American Staffordshire Terrie',
    'Anatolian Shepherd',
    'Basenji',
    'Basset Hound',
    'Beagle',
    'Bearded Collie',
    'Bedlington Terrier',
    'Belgian Sheepdog',
    'Bichon Frise',
    'Boiadeiro Australiano',
    'Boiadeiro da Flandres',
    'Boiadeiro de Berna',
    'Border Collie',
    'Border Terrier',
    'Borzoi',
    'Boston Terrier',
    'Boxer',
    'Braco Alemão de Pelo Curto',
    'Braco Alemão de Pelo Duro',
    'Brittany',
    'Buldogue Francês',
    'Buldogue',
    'Bull Terrier Miniatura',
    'Bull Terrier',
    'Bulmastife',
    'Cairn Terrier',
    'Cão d`Água Americano',
    'Cão d`Água Português',
    'Cão de Canaã',
    'Cão de Crista Chinês',
    'Cão de Montanha dos Pirenéus',
    'Cão de Santo Humberto',
    'Cão Esquimó Americano',
    'Cavalier King Charles Spaniel',
    'Chesapeake Bay Retriever',
    'Chihuahua',
    'Chinese Shar - Pei',
    'Chow Chow',
    'Clumber Spaniel',
    'Cocker Spaniel Inglês',
    'Cocker Spaniel',
    'Collie',
    'Coonhound Preto e Castanho',
    'Curly - Coated Retriever',
    'Dachshund',
    'Dálmata',
    'Dandle Dinmont Terrier',
    'Deerhound',
    'Doberman Pinscher',
    'Dogue Alemão',
    'Elkhound Norueguês Preto',
    'English Toy Spaniel',
    'Field Spaniel',
    'Flat-coated Retriever',
    'Fox Terrier de Pelo Liso',
    'Fox Terrier Miniatura',
    'Foxhound Americano',
    'Foxhound Inglês',
    'Galgo Afegão',
    'Galgo Inglês',
    'Galgo Italiano',
    'Golden Retriever',
    'Grande Boiadeiro Suíço',
    'Griffon de Aponte de Pelo Duro Korthals',
    'Griffon de Bruxelas',
    'Harrier',
    'Havanese',
    'Husky Siberiano',
    'Irish Setter',
    'Jack Russell Terrier',
    'Keeshond',
    'Kelpie Australiano',
    'Kerry Blue Terrier',
    'Komondor',
    'Kuvasz',
    'Labrador Retriever',
    'Lakeland Terrier',
    'Lhasa Apso',
    'Lowchen',
    'Lulu da Pomerânia',
    'Malamute do Alasca',
    'Maltês',
    'Manchester Terrier',
    'Mastim',
    'Norfolk Terrier',
    'Norwich Terrier',
    'Old English Sheepdog',
    'Other Breed',
    'Otterhound',
    'Papillon',
    'Pastor de Brie',
    'Pastor de Shetland',
    'Pastor-alemão',
    'Pastor-australiano',
    'Pastor-belga Malinois',
    'Pastor-belga Tervuren',
    'Pastor-polonês da Planície',
    'Pequeno Basset Griffon da Vendeia',
    'Pequinês',
    'Pharaoh Hound',
    'Pinscher Alemão',
    'Pinscher Miniatura',
    'Pit Bull Terrier Americano',
    'Plott Hound',
    'Podengo Ibicenco',
    'Pointer Inglês',
    'Poodle',
    'Pug',
    'Puli',
    'Raça mista',
    'Rat Terrier',
    'Rhodesian Ridgeback',
    'Rottweiler',
    'Saluki',
    'Samoiedo',
    'São-bernardo',
    'Schipperke',
    'Schnauzer Gigante',
    'Schnauzer Miniatura',
    'Sealyham Terrier',
    'Setter Gordon',
    'Setter Inglês',
    'Shiba',
    'Shih Tzu',
    'Silky Terrier',
    'Skye Terrier',
    'Soft-coated Wheaten Terrier',
    'Spaniel d`Água Irlandês',
    'Spaniel Japonês',
    'Spaniel Tibetano',
    'Spinone Italiano',
    'Spitz Finlandês',
    'Springer Spaniel de Gales',
    'Springer Spaniel Inglês',
    'Staffordshire Bull Terrier',
    'Sussex Spaniel',
    'Terra-nova',
    'Terrier Australiano',
    'Terrier Escocês',
    'Terrier Irlandês',
    'Terrier Tibetano',
    'Vizsla',
    'Weimaraner',
    'Welsh Corgi (Cardigan)',
    'Welsh Corgi Pembroke',
    'Welsh Fox Terrier de Pelo Duro',
    'Welsh Terrier',
    'West Highland White Terrier',
    'Whippet',
    'Wolfhound Irlandês',
    'Yorkshire Terrier',
  ],
  es: [
    'Affenpinscher',
    'Airedale Terrier',
    'Akita',
    'American Staffordshire Terrie',
    'Anatolian Shepherd',
    'Basenji',
    'Basset Hound',
    'Beagle',
    'Bedlington Terrier',
    'Belgian Sheepdog',
    'Bichon Frise',
    'Bichón habanero',
    'Bichón maltés',
    'Black and tan coonhound',
    'Bobtail',
    'Border collie',
    'Border terrier',
    'Borzoi',
    'Boston Terrier',
    'Bóxer',
    'Boyero de Berna',
    'Boyero de Flandes',
    'Braco alemán de pelo corto',
    'Braco alemán de pelo duro',
    'Braco de Weimar',
    'Bretón',
    'Bull terrier miniatura',
    'Bull terrier',
    'Bulldog francés',
    'Bulldog inglés',
    'Bullmastiff',
    'Cairn terrier',
    'Caniche',
    'Cão de água português',
    'Carlino',
    'Cavalier King Charles Spaniel',
    'Cazador de alces noruego negro',
    'Chihuahua',
    'Chinese Shar - Pei',
    'Chow Chow',
    'Clumber spaniel',
    'Cocker spaniel inglés',
    'Cocker spaniel',
    'Collie barbudo',
    'Collie',
    'Corgi galés de Cardigan',
    'Corgi galés de Pembroke',
    'Crestado chino',
    'Dachshund',
    'Dálmata',
    'Dandle Dinmont Terrier',
    'Dóberman Pinscher',
    'English Toy Spaniel',
    'Fox terrier de pelo duro galés',
    'Fox terrier de pelo liso',
    'Foxhound americano',
    'Foxhound inglés',
    'Galgo inglés',
    'Golden retriever',
    'Gran boyero suizo',
    'Gran danés',
    'Grifón de Bruselas',
    'Grifón korthals',
    'Grifón vandeano basset pequeño',
    'Harrier',
    'Husky siberiano',
    'Irish Setter',
    'Irish soft coated wheaten terrier',
    'Jack Russell Terrier',
    'Keeshond',
    'Kelpie australiano',
    'Kerry Blue Terrier',
    'Komondor',
    'Kuvasz',
    'Labrador Retriever',
    'Lakeland Terrier',
    'Lebrel afgano',
    'Lebrel escocés',
    'Lebrel italiano',
    'Lhasa Apso',
    'Lobero irlandés',
    'Malamute de Alaska',
    'Manchester Terrier',
    'Mastín',
    'Other Breed',
    'Otterhound',
    'Papillón',
    'Pastor alemán',
    'Pastor belga malinois',
    'Pastor belga tervuerense',
    'Pastor de Brie',
    'Pastor de las islas Shetland',
    'Pastor de Valée',
    'Pastor ganadero australiano',
    'Pastor ovejero australiano',
    'Pekinés',
    'Pequeño perro león',
    'Perro crestado rodesiano',
    'Perro de agua americano',
    'Perro de agua irlandés',
    'Perro de Canaán',
    'Perro de montaña de los Pirineos',
    'Perro de San Huberto',
    'Perro esquimal americano',
    'Pharaoh Hound',
    'Pinscher alemán',
    'Pinscher miniatura',
    'Pit bull terrier americano',
    'Plott Hound',
    'Podenco ibicenco',
    'Pointer inglés',
    'Pomeranio',
    'Puli',
    'Rat Terrier',
    'Raza mixta',
    'Retriever de Chesapeake',
    'Retriever de pelo liso',
    'Retriever de pelo rizado',
    'Rottweiler',
    'Saluki',
    'Samoyedo',
    'San Bernardo',
    'Schipperke',
    'Schnauzer gigante',
    'Schnauzer miniatura',
    'Sealyham Terrier',
    'Setter escocés',
    'Setter inglés',
    'Shiba Inu',
    'Shih Tzu',
    'Silky terrier australiano',
    'Skye Terrier',
    'Spaniel de campo',
    'Spaniel japonés',
    'Spaniel tibetano',
    'Spinone',
    'Spitz finlandés',
    'Springer spaniel galés',
    'Springer spaniel inglés',
    'Staffordshire bull terrier',
    'Sussex Spaniel',
    'Terranova',
    'Terrier australiano',
    'Terrier de Norfolk',
    'Terrier de Norwich',
    'Terrier escocés',
    'Terrier galés',
    'Terrier irlandés',
    'Terrier tibetano',
    'Toy Fox Terrier',
    'Vizsla',
    'West Highland White Terrier',
    'Whippet',
    'Yorkshire Terrier',
  ],
  kr: [
    '아프간 하운드',
    '에어데일 테리어',
    '아키타',
    '알래스칸 말라뮤트',
    '아메리칸 에스키모 개',
    '아메리칸 폭스하운드',
    '아메리칸 핏불 테리어',
    '아메리칸 스태퍼드셔 테리어',
    '아메리칸 워터 스파니엘',
    'Anatolian Shepherd',
    '오스트레일리안 캐틀독',
    '호주산 켈피',
    '오스트레일리안 셰퍼드',
    '오스트레일리안 테리어',
    '아펜핀셔',
    '바센지',
    '바셋 하운드',
    '비글',
    '비어디드 콜리',
    '베들링턴 테리어',
    '벨기에 말리노이즈',
    'Belgian Sheepdog',
    '벨기에 테르뷰렌',
    '버니즈 마운틴독',
    '비숑 프리제',
    '블랙 앤 탄 쿤하운드',
    '집요한 추적자',
    '보더 콜리',
    '보더 테리어',
    'Borzol',
    '보스턴 테리어',
    '부비에 데 플랑드르',
    '복서',
    '브리아드',
    '브리타니',
    '브뤼셀 그리폰',
    '불 테리어',
    '불독',
    '불마스티프',
    '케언 테리어',
    '가나안 개',
    'Cardigan Welsh Corgi',
    '카발리에 킹 찰스 스패니얼',
    '체서피크 베이 리트리버',
    '치와와',
    '중국 볏 개',
    'Chinese Shar - Pei',
    '차우 차우',
    '클럼버 스패니얼',
    '잉글리시 코커 스패니얼',
    'Collie',
    'Curly - Coated Retriever',
    '닥스훈트',
    '달마시안',
    'Dandle Dinmont Terrier',
    'Doberman Pinscher',
    '잉글리시 코커 스패니얼',
    '잉글리시 폭스하운드',
    '잉글리쉬 세터',
    '잉글리시 스프링거 스파니엘',
    'English Toy Spaniel',
    '필드 스파니엘',
    '핀란드 스피츠',
    'Flat - Coated Retriever',
    '프랑스 불독',
    '독일 핀셔',
    '독일 셰퍼드',
    'German Shorthaired Pointer',
    'German Wirehaired Pointer',
    '자이언트 슈나우저',
    '골든 리트리버',
    '고든 세터',
    '그레이트 데인',
    '그레이트 피레네 산맥',
    'Greater Swiss Mountain Dog',
    '그레이하운드',
    '해리어',
    '하바네세인',
    'Ibizan Hound',
    'Irish Setter',
    '아이리쉬 테리어',
    '아이리시 워터 스파니엘',
    '아이리시 울프하운드',
    '이탈리안 그레이하운드',
    '잭 러셀 테리어',
    '일본 친',
    '케이스혼드',
    '케리 블루 테리어',
    '코몬도르',
    '쿠바즈',
    '래브라도 리트리버',
    '레이크랜드 테리어',
    '라사압소',
    '로첸',
    '몰티즈',
    '맨체스터 테리어',
    '마스티프',
    '미니어처 불 테리어',
    '미니어처 핀셔',
    '미니어처 슈나우저',
    '혼합 품종',
    '뉴펀들랜드',
    '노퍽 테리어',
    '노르웨이 엘크하운드 블랙',
    '노리치 테리어',
    '올드 잉글리시 쉽독',
    'Other Breed',
    '수달하운드',
    '빠삐용',
    '페키니즈',
    '펨브로크 웰시코기',
    'Petits Bassets Griffons Vendeen',
    '파라오 하운드',
    '플롯 하운드',
    '잉글리시 포인터',
    '폴란드 저지대 양치기 개',
    '포메라니안',
    '푸들',
    '포르투갈 워터독',
    '이긴 흙',
    '풀리',
    '쥐 테리어',
    '로디지안 리지백',
    '로트와일러',
    '살루키',
    '사모예드',
    '시퍼키',
    'Scottish Deerhound',
    '스코틀랜드 테리어',
    '씰리엄 테리어',
    'Sheltie',
    '셰틀랜드 양치기 개',
    '시바',
    '시츄',
    '시베리안 허스키',
    '오스트레일리안 실키 테리어',
    '스카이 테리어',
    '스무스 폭스 테리어',
    '아이리쉬 소프트 코팅 휘튼 테리어',
    '이탈리안 스피노네',
    'St.Bernard',
    '스태퍼드셔 불테리어',
    'Standard Schnauzer',
    '서식스 스파니엘',
    '티벳탄 스파니엘',
    '티베트 테리어',
    '토이 폭스 테리어',
    '털이 많은 비즐라',
    '바이마라너',
    '웰시 스프링거 스파니엘',
    '웨일스 테리어',
    '웨스트 하이랜드 화이트 테리어',
    '경전차',
    '웰시 와이어 폭스 테리어',
    'Wirehaired Pointing Griffon',
    '요크셔 테리어',
  ],
  jp: [
    'アフガン・ハウンド',
    'エアデール テリア',
    '秋田',
    'アラスカンマラミュート',
    'アメリカンエスキモードッグ',
    'アメリカンフォックスハウンド',
    'アメリカン・ピット・ブル・テリア',
    'アメリカン・スタッフォードシャー・テリア',
    'アメリカン ウォーター スパニエル',
    'Anatolian Shepherd',
    'オーストラリアン・キャトル・ドッグ',
    'オーストラリアンケルピー',
    'オーストラリアン シェパード',
    'オーストラリアンテリア',
    'アーフェンピンシャー',
    'バセンジー',
    'バセットハウンド',
    'Beagle',
    'ビアデッド・コリー',
    'ベドリントン・テリア',
    'ベルギーマリノア',
    'Belgian Sheepdog',
    'ベルギーのテルビュレン',
    'バーニーズ・マウンテン・ドッグ',
    'ビションフリーゼ',
    'ブラック アンド タン クーンハウンド',
    'ブラッドハウンド',
    'ボーダーコリー',
    'ボーダーテリア',
    'Borzol',
    'ボストンテリア',
    'ブービエ デ フランドル',
    'ボクサー',
    'ブリアード',
    'ブルターニュ',
    'ブリュッセル・グリフォン',
    'ブルテリア',
    'ブルドッグ',
    'ブルマスティフ',
    'ケアンテリア',
    'カナン・ドッグ',
    'Cardigan Welsh Corgi',
    'キャバリア・キング・チャールズ・スパニエル',
    'チェサピークベイレトリバー',
    'チワワ',
    'チャイニーズ・クレステッド・ドッグ',
    'Chinese Shar - Pei',
    'チャウチャウ',
    'クランバースパニエル',
    'イングリッシュコッカースパニエル',
    'ラフ・コリー',
    'Curly - Coated Retriever',
    'ダックスフント',
    'ダルメシアン',
    'Dandle Dinmont Terrier',
    'Doberman Pinscher',
    'イングリッシュ・フォックスハウンド',
    'イングリッシュセッター',
    'イングリッシュ・スプリンガー・スパニエル',
    'English Toy Spaniel',
    'フィールドスパニエル',
    'フィンランドのスピッツ',
    'Flat - Coated Retriever',
    'フレンチ・ブルドッグ',
    'ジャーマンピンシャー',
    '古いジャーマン シェパード犬',
    'German Shorthaired Pointer',
    'German Wirehaired Pointer',
    'ジャイアントシュナウザー',
    'ゴールデンレトリバー',
    'ゴードン・セッター',
    'グレートデーン',
    'グレート ピレネー',
    'Greater Swiss Mountain Dog',
    'グレイハウンド',
    'ハリアー',
    'ハバニーズ',
    'Ibizan Hound',
    'Irish Setter',
    'アイリッシュ テリア',
    'アイリッシュ ウォーター スパニエル',
    'アイリッシュ・ウルフハウンド',
    'イタリアン・グレーハウンド',
    'ジャックラッセルテリア',
    '狆',
    'キースホンド',
    'ケリーブルーテリア',
    'コモンドール',
    'クヴァシュ',
    'ラブラドール・レトリバー',
    'レイクランドテリア',
    'ラサ・アプソ',
    '小さなライオン犬',
    'マルタ語',
    'マンチェスターテリア',
    'マスティフ',
    'ミニチュア・ブル・テリア',
    'ミニチュアピンシャー',
    'ミニチュアシュナウザー',
    '雑種',
    'ニューファンドランド',
    'ノーフォークテリア',
    'ノルウェージャン エルクハウンド ブラック',
    'ノリッジ・テリア',
    'オールド・イングリッシュ・シープドッグ',
    'Other Breed',
    'オッターハウンド',
    'パピヨン',
    'ペキニーズ',
    'ウェルシュ・コーギー・ペンブローク',
    'Petits Bassets Griffons Vendeen',
    'ファラオ・ハウンド',
    'プロット・ハウンド',
    'プデルポインター',
    'ポリッシュ ローランド シープドッグ',
    'ポメラニアン',
    'プードル',
    'ポーチュギーズ・ウォーター・ドッグ',
    'パグ',
    '埔里',
    'ラットテリア',
    'ローデシアン・リッジバック',
    'ロットワイラー',
    'サルーキ',
    'サモエド',
    'シッパーキ',
    'Scottish Deerhound',
    'スコティッシュテリア',
    'シーリハムテリア',
    'Sheltie',
    'シェットランド・シープドッグ',
    '芝',
    'シーズー',
    'シベリアンハスキー',
    'オーストラリアン シルキー テリア',
    'スカイテリア',
    'スムースフォックステリア',
    'アイリッシュ ソフト コーテッド ウィーン テリア',
    'イタリアのスピノーネ',
    'St.Bernard',
    'スタッフォードシャー・ブル・テリア',
    'Standard Schnauzer',
    'サセックス・スパニエル',
    'チベタン・スパニエル',
    'チベタンテリア',
    'トイ・フォックス・テリア',
    'ワイヤーヘアード・ヴィズラ',
    'ワイマラナー',
    'ウェルシュ・スプリンガー・スパニエル',
    'ウェルシュ・テリア',
    'ウエストハイランドホワイトテリア',
    'ウィペット',
    'ウェルシュ・ワイヤー・フォックス・テリア',
    'Wirehaired Pointing Griffon',
    'ヨークシャーテリア',
  ],
  pl: [
    'Chart afgański',
    'Airedale Terrier',
    'Akita',
    'Alaskan Malamute',
    'Amerykański pies eskimoski',
    'Amerykański foxhound',
    'Amerykański Pit Bull Terrier',
    'Amerykański Staffordshire Terrier',
    'Amerykański spaniel wodny',
    'Anatolian Shepherd',
    'Australian Cattle Dog',
    'Kelpie australijski',
    'Owczarek australijski',
    'Australijski Terier',
    'Affenpinscher',
    'Basenji',
    'Basset Hound',
    'Beagle',
    'Brodaty Collie',
    'Bedlington Terrier',
    'Malinois belgijski',
    'Belgian Sheepdog',
    'Tervuren belgijski',
    'Berneński pies pasterski',
    'Bichon Frise',
    'Black and Tan Coonhound',
    'Bloodhound',
    'Border Collie',
    'Border Terrier',
    'Borzol',
    'Boston Terrier',
    'Bouvier des Flandres',
    'Bokser',
    'Briard',
    'Bretania',
    'Gryfon brukselski',
    'Bull Terrier',
    'Bulldog',
    'Bullmastiff',
    'Cairn Terrier',
    'Pies kanadyjski',
    'Cardigan Welsh Corgi',
    'Cavalier King Charles Spaniel',
    'Chesapeake Bay Retriever',
    'Chihuahua',
    'Chiński grzywacz',
    'Chiński Shar - Pei',
    'Chow Chow',
    'Clumber Spaniel',
    'Cocker spaniel',
    'Collie',
    'Curly - Coated Retriever',
    'Jamnik',
    'Dalmatyńczyk',
    'Dandle Dinmont Terrier',
    'Doberman Pinscher',
    'Cocker spaniel angielski',
    'Chart angielski',
    'Seter angielski',
    'Springer spaniel angielski',
    'English Toy Spaniel',
    'Spaniel polny',
    'Szpic fiński',
    'Flat - Coated Retriever',
    'Buldog francuski',
    'Pinczer niemiecki',
    'Owczarek niemiecki',
    'German Shorthaired Pointer',
    'German Wirehaired Pointer',
    'Sznaucer olbrzym',
    'Golden Retriever',
    'Gordon Setter',
    'Dog niemiecki',
    'Wielki pirenejski',
    'Greater Swiss Mountain Dog',
    'Greyhound',
    'Harrier',
    'Hawańczyk',
    'Ibizan Hound',
    'Irish Setter',
    'Terier irlandzki',
    'Spaniel irlandzki wodny',
    'Wilczarz irlandzki',
    'Chart włoski',
    'Jack Russell Terrier',
    'Chin japoński',
    'Keeshond',
    'Kerry Blue Terrier',
    'Komondor',
    'Kuvasz',
    'Labrador Retriever',
    'Lakeland Terrier',
    'Lhasa Apso',
    'Lowchen',
    'Maltańczyk',
    'Manchester Terrier',
    'Mastif',
    'Bull terier miniaturowy',
    'Pinczer miniaturowy',
    'Sznaucer miniaturowy',
    'Rasy mieszane',
    'Nowofundland',
    'Norfolk Terrier',
    'Elkhund norweski czarny',
    'Norwich Terrier',
    'Owczarek staroangielski',
    'Inna rasa',
    'Otterhound',
    'Papillon',
    'Pekińczyk',
    'Pembroke Welsh Corgi',
    'Petits Bassets Griffons Vendeen',
    'Ogar faraona',
    'Plott',
    'Pointer',
    'Polski owczarek nizinny',
    'Pomorski',
    'Pudel',
    'Portugalski pies wodny',
    'Mops',
    'Puli',
    'Terier szczurzy',
    'Rhodesian Ridgeback',
    'Rottweiler',
    'Saluki',
    'Samoyed',
    'Schipperke',
    'Scottish Deerhound',
    'Terier szkocki',
    'Sealyham Terrier',
    'Sheltie',
    'Owczarek szetlandzki',
    'Shiba Inu',
    'Shih Tzu',
    'Husky syberyjski',
    'Terier jedwabisty',
    'Skye Terrier',
    'Foksterier gładkowłosy',
    'Terier szorstkowłosy',
    'Szpic włoski',
    'St.Bernard',
    'Staffordshire Bull Terrier',
    'Standard Schnauzer',
    'Sussex Spaniel',
    'Spaniel tybetański',
    'Terier tybetański',
    'Toy Fox Terrier',
    'Vizsla',
    'Wyżeł weimarski',
    'Springer spaniel walijski',
    'Terier walijski',
    'West Highland White Terrier',
    'Whippet',
    'Wire Fox Terrier',
    'Wirehaired Pointing Griffon',
    'Yorkshire Terrier',
  ],
  tr: [
    'Afgan Tazısı',
    'Airedale Terrier',
    'Akita',
    'Alaska Malamutu',
    'Amerikan Eskimo Köpeği',
    'Amerikan Tilki Tazısı',
    'Amerikan Pit Bull Terrier',
    'Amerikan Staffordshire Terrier',
    'Amerikan Su Spanieli',
    'Anadolu Çoban Köpeği',
    'Avustralya Sığır Köpeği',
    'Avustralya Kelpie',
    'Avustralya Çoban Köpeği',
    'Avustralya Terrier',
    'Affenpinscher',
    'Basenji',
    'Basset Tazısı',
    'Beagle',
    'Sakallı Collie',
    'Bedlington Terrier',
    'Belçika Malinois',
    'Belçika Çoban Köpeği',
    'Belçika Tervuren Çoban Köpeği',
    'Bernese Dağ Köpeği',
    'Bichon Frise',
    'Siyah ve Bronz Coonhound',
    'Bloodhound',
    'Border Collie',
    'Border Terrier',
    'Borzol',
    'Boston Terrier',
    'Flandres Bouviers',
    'Boxer',
    'Briard',
    'Brittany',
    'Brüksel Griffonu',
    'Bull Terrier',
    'Bulldog',
    'Bullmastiff',
    'Cairn Terrier',
    'Kenan Köpeği',
    'Cardigan Galler Corgi',
    'Cavalier King Charles Spaniel',
    'Chesapeake Bay Retriever',
    'Chihuahua',
    'Çin Tepeli Köpeği',
    'Chinese Shar - Pei',
    'Chow Chow',
    'Clumber Spaniel',
    'Cocker Spaniel',
    'Collie',
    'Curly - Coated Retriever',
    'Dachshund',
    'Dalmatiyan',
    'Dandle Dinmont Terrier',
    'Doberman Pinscher',
    'İngiliz Cocker Spaniel',
    'İngiliz Tilki Av Köpeği',
    'İngiliz Setter',
    'İngiliz Springer Spaniel',
    'İngiliz Oyuncak Spaniel',
    'Field Spaniel',
    'Fin Spitz',
    'Flat - Coated Retriever',
    'Fransız Bulldog',
    'Alman Pinscher',
    'Alman Shepherd',
    'Alman Kısa Tüylü Pointer',
    'Alman Kaba Tüylü Pointer',
    'Dev Schnauzer',
    'Golden Retriever',
    'Gordon Setter',
    'Büyük Dane',
    'Büyük Pirene',
    'Büyük İsviçre Dağ Köpeği',
    'Greyhound',
    'Harrier',
    'Havanese',
    'İbiza Tazısı',
    'İrlanda Setter',
    'İrlanda Terrier',
    'İrlanda Su Spaniel',
    'İrlanda Kurt Tazısı',
    'İtalyan Greyhound',
    'Jack Russell Terrier',
    'Japon Chini',
    'Keeshond',
    'Kerry Mavi Terrier',
    'Komondor',
    'Kuvasz',
    'Labrador Retriever',
    'Lakeland Terrier',
    'Lhasa Apso',
    'Lowchen',
    'Maltaiz',
    'Manchester Terrier',
    'Mastif',
    'Minyatür Boğa Terrier',
    'Minyatür Pinscher',
    'Minyatür Schnauzer',
    'Melez',
    'Newfoundland',
    'Norfolk Terrier',
    'Norveç Siyah Elkhound',
    'Norwich Terrier',
    'Eski İngiliz Çoban Köpeği',
    'Diğer Irk',
    'Su Samuru Tazısı',
    'Papillon',
    'Pekin Köpeği',
    'Pembroke Welsh Corgi',
    'Petit Basset Griffon Vendeen',
    'Firavun Tazısı',
    'Plott',
    'Pointer',
    'Polonyalı Düşük Alan Çoban Köpeği',
    'Pomeranyalı',
    'Kaniş',
    'Portekiz Su Köpeği',
    'Pug',
    'Puli',
    'Rat Terrier',
    'Rodezyan Ridgeback',
    'Rottweiler',
    'Saluki',
    'Samoyed',
    'Schipperke',
    'İskoç Geyik Tazısı',
    'İskoç Terrier',
    'Sealyham Terrier',
    'Sheltie',
    'Shetland Çoban Köpeği',
    'Shiba Inu',
    'Shih Tzu',
    'Sibirya Husky',
    'İpek Terrier',
    'Skye Terrier',
    'Düz Fox Terrier',
    'Yumuşak Tüylü Wheaten Terrier',
    'İtalyan Spinone',
    'St.Bernard',
    'Staffordshire Boğa Terrier',
    'Standart Schnauzer',
    'Sussex Spaniel',
    'Tibet Spaniel',
    'Tibet Terrier',
    'Oyuncak Fox Terrier',
    'Vizsla',
    'Weimaraner',
    'Galler Springer Spaniel',
    'Galler Terrier',
    'Batı Yüksekland Beyaz Terrier',
    'Whippet',
    'Tel Fox Terrier',
    'Telli Tüylü Pointer Griffon',
    'Yorkshire Terrier',
  ],
  ro: [
    'Afghan Hound',
    'Airedale Terrier',
    'Akita',
    'Alaskan Malamute',
    'American Eskimo Dog',
    'American Foxhound',
    'American Pit Bull Terrier',
    'American Staffordshire Terrier',
    'American Water Spaniel',
    'Anatolian Shepherd',
    'Australian Cattle Dog',
    'Australian Kelpie',
    'Australian Shepherd',
    'Australian Terrier',
    'Affenpinscher',
    'Basenji',
    'Basset Hound',
    'Beagle',
    'Bearded Collie',
    'Bedlington Terrier',
    'Belgian Malinois',
    'Belgian Sheepdog',
    'Belgian Tervuren',
    'Bernese Mountain Dog',
    'Bichon Frise',
    'Black and Tan Coonhound',
    'Bloodhound',
    'Border Collie',
    'Border Terrier',
    'Borzol',
    'Boston Terrier',
    'Bouvier des Flandres',
    'Boxer',
    'Briard',
    'Brittany',
    'Brussels Griffon',
    'Bull Terrier',
    'Bulldog',
    'Bullmastiff',
    'Cairn Terrier',
    'Canaan Dog',
    'Cardigan Welsh Corgi',
    'Cavalier King Charles Spaniel',
    'Chesapeake Bay Retriever',
    'Chihuahua',
    'Chinese Crested',
    'Chinese Shar - Pei',
    'Chow Chow',
    'Clumber Spaniel',
    'Cocker Spaniel',
    'Collie',
    'Curly - Coated Retriever',
    'Dachshund',
    'Dalmatian',
    'Dandle Dinmont Terrier',
    'Doberman Pinscher',
    'English Cocker Spaniel',
    'English Foxhound',
    'English Setter',
    'English Springer Spaniel',
    'English Toy Spaniel',
    'Field Spaniel',
    'Finnish Spitz',
    'Flat - Coated Retriever',
    'French Bulldog',
    'German Pinscher',
    'German Shepherd',
    'German Shorthaired Pointer',
    'German Wirehaired Pointer',
    'Giant Schnauzer',
    'Golden Retriever',
    'Gordon Setter',
    'Great Dane',
    'Great Pyrenees',
    'Greater Swiss Mountain Dog',
    'Greyhound',
    'Harrier',
    'Havanese',
    'Ibizan Hound',
    'Irish Setter',
    'Irish Terrier',
    'Irish Water Spaniel',
    'Irish Wolfhound',
    'Italian Greyhound',
    'Jack Russell Terrier',
    'Japanese Chin',
    'Keeshond',
    'Kerry Blue Terrier',
    'Komondor',
    'Kuvasz',
    'Labrador Retriever',
    'Lakeland Terrier',
    'Lhasa Apso',
    'Lowchen',
    'Maltese',
    'Manchester Terrier',
    'Mastiff',
    'Miniature Bull Terrier',
    'Miniature Pinscher',
    'Miniature Schnauzer',
    'Mixed Breed',
    'Newfoundland',
    'Norfolk Terrier',
    'Norwegian Elkhound',
    'Norwich Terrier',
    'Old English Sheepdog',
    'Other Breed',
    'Otterhound',
    'Papillon',
    'Pekingese',
    'Pembroke Welsh Corgi',
    'Petits Bassets Griffons Vendeen',
    'Pharaoh Hound',
    'Plott',
    'Pointer',
    'Polish Lowland Sheepdog',
    'Pomeranian',
    'Poodle',
    'Portuguese Water Dog',
    'Pug',
    'Puli',
    'Rat Terrier',
    'Rhodesian Ridgeback',
    'Rottweiler',
    'Saluki',
    'Samoyed',
    'Schipperke',
    'Scottish Deerhound',
    'Scottish Terrier',
    'Sealyham Terrier',
    'Sheltie',
    'Shetland Sheepdog',
    'Shiba Inu',
    'Shih Tzu',
    'Siberian Husky',
    'Silky Terrier',
    'Skye Terrier',
    'Smooth Fox Terrier',
    'Soft Coated Wheaten Terrier',
    'Spinone Italiano',
    'St.Bernard',
    'Staffordshire Bull Terrier',
    'Standard Schnauzer',
    'Sussex Spaniel',
    'Tibetan Spaniel',
    'Tibetan Terrier',
    'Toy Fox Terrier',
    'Vizsla',
    'Weimaraner',
    'Welsh Springer Spaniel',
    'Welsh Terrier',
    'West Highland White Terrier',
    'Whippet',
    'Wire Fox Terrier',
    'Wirehaired Pointing Griffon',
    'Yorkshire Terrier',
  ],
}

export const POPULAR_BREEDS_FOR_DROPDOWN = [
  'Mixed Breed',
  'Golden Retriever',
  'Labrador Retriever',
  'German Shepherd',
  'American Pit Bull Terrier',
  'French Bulldog',
  'Chihuahua',
  'Border Collie',
  'Siberian Husky',
  'Yorkshire Terrier',
  'Shih Tzu',
  'Poodle',
  'Beagle',
  'Pembroke Welsh Corgi',
]
