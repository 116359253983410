import { IRemoteConfigFile, IRemoteConfigFileRaw } from 'models/config.model'

export const getWebFunnelsConfigFromRawData = (
  config: IRemoteConfigFileRaw,
): IRemoteConfigFile => ({
  signUpMethods: {
    chooseOtherMethodsShown: config.sign_up_methods.choose_other_methods_shown,
    otherMethodsShown: {
      facebook: config.sign_up_methods.other_methods_shown.facebook,
      apple: config.sign_up_methods.other_methods_shown.apple,
      google: config.sign_up_methods.other_methods_shown.google,
    },
  },
  paypalMethodShown: config.paypal_method_shown,
  webappRedirectForAndroid: config.webapp_redirect_for_android_users,
  webappRedirectForIOS: config.webapp_redirect_for_ios_users,
  vcEventLink: config.vc_event_link,
  firstScreenBtnText: config.first_screen_btn_text,
})
