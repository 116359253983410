const CALENDLY_TOKEN =
  'eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzMxNTAzNDU0LCJqdGkiOiJhY2FmMWM5Zi01ODJjLTQ2OTUtODVjMC0zMjFhZTFjZDg1MjEiLCJ1c2VyX3V1aWQiOiI1ZWU4Y2MyNi1jMmE1LTQzZjEtODQ0ZS0zNjYxNzc2MmIyNGQifQ.o2Wr0MwhevRTUpjdA8We8osAln-YuZaiFiahg9DQPuX-ZTJNRikSBCPTg7rsyrOv36uQIrtjBUXGmuNWJk_Y4Q'
export const CALENDLY_ERROR = 'Failed to load event data.'

export const CALENDLY_REQUEST_OPTIONS = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${CALENDLY_TOKEN}`,
  },
}

export const CALENDLY_POST_REQUEST_OPTIONS = {
  method: 'POST',
  keepalive: true,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${CALENDLY_TOKEN}`,
  },
}

// NOT REMOVE
export const CALENDLY_DEV_LINK =
  'https://calendly.com/d/ckzx-fh7-mkp/test-woofz-online-coaching-30-min-clone-clone'
