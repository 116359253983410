import firebase from 'firebase/app'
import {
  ICalendlyInfo,
  IDogBreed,
  IStripeAccount,
  TAnswers,
} from 'models/common.model'
import { IConfig, IRemoteConfigFile, TGiaApiKeys } from 'models/config.model'
import { IAppState } from 'models/store.model'
import { IStep, IVariant } from 'models/variant.model'
import { ISubscription as ISubscriptionNew } from 'models/subscriptions.model'

export const selectIsFetching = (state: IAppState): boolean =>
  state.common.isFetching
export const selectActionList = (state: IAppState): string[] =>
  state.common.actionList
export const selectError = (state: IAppState): any => state.common.error
export const selectCurrentVariantCohort = (state: IAppState): string =>
  state.common.currentVariant?.cohort || ''
export const selectCurrentVariant = (state: IAppState): IVariant | null =>
  state.common.currentVariant || null
export const selectCurrentVariantSteps = (state: IAppState): IStep[] =>
  state.common.currentVariant?.steps || []
export const selectConfig = (state: IAppState): IConfig | null =>
  state.common.config
export const selectGiaApiKeys = (state: IAppState): TGiaApiKeys | null =>
  state.common.config?.giaApiKeys || null
export const selectIsFirebaseCongifLoaded = (state: IAppState): boolean =>
  state.common.isFirebaseConfigLoaded
export const selectGoogleOptimizeId = (state: IAppState): string | null =>
  state.common.config?.googleOptimizeId || null
export const selectGoogleAnalyticsId = (state: IAppState): string | null =>
  state.common.config?.googleAnalyticsId || null
export const selectStripePublicKey = (state: IAppState): string | null =>
  state.common.paymentConfig?.stripe.publicKey || null
export const selectAnswers = (state: IAppState): Partial<TAnswers> | null =>
  state.common.answers
export const selectOptimizeExperimentId = (state: IAppState): string =>
  state.common.currentVariant?.optimizeExperimentId || ''
export const selectOptimizeVariantId = (state: IAppState): string =>
  state.common.optimizeVariantId
export const selectOptimizeSegmentName = (state: IAppState): string =>
  state.common.optimizeSegmentName
export const selectIsCancelOfferApplied = (state: IAppState): boolean =>
  state.common.isCancelOfferApplied
export const selectIsCancelOfferNotApplied = (state: IAppState): boolean =>
  state.common.isCancelOfferNotApplied
export const selectLanguage = (state: IAppState): string =>
  state.common.language
export const selectFirebaseRemoteConfig = (
  state: IAppState,
): firebase.remoteConfig.RemoteConfig | null =>
  state.common.firebaseRemoteConfig
export const selectShouldSendEventsByRemoteConfig = (
  state: IAppState,
): boolean => state.common.shouldSendEventsByRemoteConfig
export const selectFirebaseConfigFile = (state: IAppState): IRemoteConfigFile =>
  state.common.firebaseConfigFile
export const selectIsPaidTrialModalShown = (state: IAppState): boolean =>
  state.common.isPaidTrialModalShown
export const selectIsLoggerInitialized = (state: IAppState): boolean =>
  state.common.isLoggerInitialized
export const selectCalendlyInfo = (state: IAppState): ICalendlyInfo =>
  state.common.calendly

export const selectAppName = (state: IAppState): string => state.common.appName

export const selectSubscriptions = (state: IAppState): ISubscriptionNew[] =>
  state.common.subscriptions

export const selectSubscriptionsPlansLoadedFlag = (state: IAppState): boolean =>
  state.common.subscriptionsPlansWasLoaded

export const selectPayPalClientId = (state: IAppState): string | null =>
  state.common.paymentConfig?.paypal.clientId || null

export const selectStripeAccount = (state: IAppState): IStripeAccount => ({
  accountId: state.common.paymentConfig?.stripe.accountId || '',
  accountName: state.common.paymentConfig?.stripe.accountName || '',
})

export const selectIsWebappFlag = (state: IAppState): boolean =>
  state.common.isWebAppFunnel

export const selectBreeds = (state: IAppState): Record<string, IDogBreed[]> =>
  state.common.breeds
