// eslint-disable-line max-lines
import {
  PaymentMethodResult,
  PaymentRequestPaymentMethodEvent,
  Stripe,
  StripeCardNumberElement,
  StripeError,
} from '@stripe/stripe-js'
import { paymentApi } from 'api'
import { createProductName } from 'helpers/createProductName'
import { IAction, IAppState, TAppDispatchThunk } from 'models/store.model'
import { ISubscription as ISubscriptionV2 } from 'models/subscriptions.model'
import {
  DEFAULT_CARDHOLDER_NAME,
  PaymentSystem,
  USER_HAS_SUBSCRIPTION_ERROR,
} from 'modules/payment/constants'
import { getRedirectUrl } from 'modules/payment/helpers'
import { CENTS_IN_DOLLAR, VC_COHORT } from 'root-constants'
import {
  setErrorAction,
  startFetching,
  stopFetching,
} from 'root-redux/actions/common'
import {
  selectAnswers,
  selectAppName,
  selectCalendlyInfo,
  selectCurrentVariantCohort,
  selectIsWebappFlag,
  selectLanguage,
  selectOptimizeExperimentId,
  selectOptimizeSegmentName,
  selectOptimizeVariantId,
  selectStripeAccount,
} from 'root-redux/selects/common'
import { selectEmail, selectUUID } from 'root-redux/selects/user'
import { eventLogger } from 'services/eventLogger.service'
import { PageId } from 'src/constants/pages'
import { setVCSubscriptionData } from 'helpers/setVCSubscriptionData'
import { sendInfoToGoogleSheet } from 'helpers/sendInfoToGoogleSheet'
import { checkIsRetryAllowed } from '../helpers/checkIsRetryAllowed'
import { logFailedPayment } from '../helpers/logFailedPayment'
import { logSuccessfulPayment } from '../helpers/logSuccessfulPayment'
import {
  selectCurrency,
  selectPaymentClientSecret,
  selectPlanId,
  selectProductPrice,
  selectSubscriptionFullPrice,
  selectSubscriptionId,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialFullPrice,
  selectTrialDurationDays,
} from './selects'
import { TPaymentOptions } from '../types'

const MODULE_NAME = 'PAYMENT'

export const SET_SUBSCRIPTION = `${MODULE_NAME}/SET_SUBSCRIPTION`
export const SET_CANCEL_OFFER = `${MODULE_NAME}/SET_CANCEL_OFFER`
export const PURCHASE = `${MODULE_NAME}/PURCHASE`
export const CHECK_3D_SECURE = `${MODULE_NAME}/CHECK_3D_SECURE`
export const SET_3D_SECURE_IFRAME_URL = `${MODULE_NAME}/SET_3D_SECURE_IFRAME_URL`
export const RESET_3D_SECURE_IFRAME_URL = `${MODULE_NAME}/RESET_3D_SECURE_IFRAME_URL`
export const SET_PAYMENT_CLIENT_SECRET = `${MODULE_NAME}/SET_PAYMENT_CLIENT_SECRET`
export const SET_TRIAL_PERIOD_DAYS = `${MODULE_NAME}/SET_TRIAL_PERIOD_DAYS`
export const SET_SUBSCRIPTION_ID = `${MODULE_NAME}/SET_SUBSCRIPTION_ID`
export const SET_IS_PAYMENT_FLOWS_SHOWN = `${MODULE_NAME}/SET_IS_PAYMENT_FLOWS_SHOWN`
export const SET_PLANS_WAS_LOADED = `${MODULE_NAME}/SET_PLANS_WAS_LOADED`
export const SET_PAYMENT_SYSTEM = `${MODULE_NAME}/SET_PAYMENT_SYSTEM`
export const SET_PAYMENT_OPTIONS = `${MODULE_NAME}/SET_PAYMENT_OPTIONS`

const set3DSecureIframeUrlAction = (payload: string): IAction<string> => ({
  type: SET_3D_SECURE_IFRAME_URL,
  payload,
})

const reset3DSecureIframeUrlAction = (): IAction<any> => ({
  type: RESET_3D_SECURE_IFRAME_URL,
})

const setPaymentClientSecretAction = (payload: string): IAction<string> => ({
  type: SET_PAYMENT_CLIENT_SECRET,
  payload,
})

export const setPaymentSystemAction = (payload: string): IAction<string> => ({
  type: SET_PAYMENT_SYSTEM,
  payload,
})

const setTrialPeriodDaysAction = (payload: number): IAction<number> => ({
  type: SET_TRIAL_PERIOD_DAYS,
  payload,
})

const setSubscriptionIdAction = (payload: string): IAction<string> => ({
  type: SET_SUBSCRIPTION_ID,
  payload,
})

const getErrorActionPayload = ({ type, message }: StripeError): string =>
  message || type

export const setPaymentRequestOptions = (
  payload: TPaymentOptions,
): IAction<TPaymentOptions> => ({
  type: SET_PAYMENT_OPTIONS,
  payload,
})

export const setSubscriptionsPlansWasLoadedAction = (
  payload: boolean,
): IAction<boolean> => ({
  type: SET_PLANS_WAS_LOADED,
  payload,
})

export const setSelectedSubscriptionV2Action = (
  payload: ISubscriptionV2,
): IAction<ISubscriptionV2> => ({
  type: SET_SUBSCRIPTION,
  payload,
})

export const setCancelOfferAction = (
  payload: ISubscriptionV2,
): IAction<ISubscriptionV2> => ({
  type: SET_CANCEL_OFFER,
  payload,
})

export const setIsPaymentFlowsShownAction = (
  payload: boolean,
): IAction<boolean> => ({
  type: SET_IS_PAYMENT_FLOWS_SHOWN,
  payload,
})

export const purchaseAction = ({
  stripe,
  paymentPageId,
  card,
  name,
  createPaymentResFromDigitalWallet,
  screenName,
  currency = 'USD',
}: {
  stripe: Stripe
  paymentPageId: PageId
  card?: StripeCardNumberElement
  name?: string
  screenName?: string
  createPaymentResFromDigitalWallet?: PaymentRequestPaymentMethodEvent
  currency?: string
}): any => async (
  dispatch: TAppDispatchThunk<any>,
  getState: () => IAppState,
): Promise<void> => {
  const state = getState()
  const appName = selectAppName(state)
  const uuid = selectUUID(state)
  const productPrice = selectProductPrice(state)
  const currentPrice = selectSubscriptionFullPrice(state)
  const trialPeriodPrice = selectSubscriptionTrialFullPrice(state)
  const trialPeriodDays = selectTrialDurationDays(state)
  const periodName = selectSubscriptionPeriodName(state)
  const periodQuantity = selectSubscriptionPeriodQuantity(state)
  const optimizeVariantId = selectOptimizeVariantId(state)
  const optimizeExperimentId = selectOptimizeExperimentId(state)
  const optimizeSegmentName = selectOptimizeSegmentName(state)
  const email = selectEmail(state)
  const cohort = selectCurrentVariantCohort(state)
  const answers = selectAnswers(state)
  const calendlyBody = selectCalendlyInfo(state)
  const stripeAccount = selectStripeAccount(state)
  const planId = selectPlanId(state)
  const lang = selectLanguage(state)
  const webapp = selectIsWebappFlag(state)
  const userAnswers =
    answers || JSON.parse(localStorage.getItem(VC_COHORT) || '{}') || {}

  if (!currentPrice) {
    console.error('Error: no subscriptions plan selected')
    return
  }

  dispatch(startFetching(PURCHASE))

  const productId = createProductName({
    periodName,
    periodQuantity,
    price: currentPrice,
  })

  const paymentMethod =
    createPaymentResFromDigitalWallet?.paymentMethod?.card?.wallet?.type

  eventLogger.logPurchaseStarted({
    productId,
    priceDetails: {
      price: currentPrice,
      trial: !!trialPeriodPrice,
      currency,
    },
    paymentMethod,
    optimizeExperimentId,
    optimizeVariantId,
    optimizeSegmentName,
    email,
    cohort,
    stripeAccount,
    screenName,
    lang,
    webapp,
  })
  window.ttq.identify({ email })
  window.ttq.track('AddPaymentInfo')
  window.snaptr('track', 'ADD_BILLING')

  try {
    const createPaymentResponse =
      card && !createPaymentResFromDigitalWallet
        ? await stripe.createPaymentMethod({
            type: 'card',
            card,
            billing_details: {
              name: name || DEFAULT_CARDHOLDER_NAME,
              email: email || DEFAULT_CARDHOLDER_NAME,
            },
          })
        : (createPaymentResFromDigitalWallet as PaymentMethodResult)

    if (!createPaymentResponse?.paymentMethod && createPaymentResponse?.error) {
      const {
        error: { type, code, message },
      } = createPaymentResponse

      eventLogger.logPurchaseFailed({
        productId,
        priceDetails: {
          price: currentPrice,
          trial: !!trialPeriodPrice,
          currency,
        },
        error: {
          type,
          code,
          description: message,
        },
        paymentMethod,
        optimizeExperimentId,
        optimizeVariantId,
        optimizeSegmentName,
        stripeAccount,
        email,
        isRetry: false,
        lang,
        webapp,
      })
      dispatch(setErrorAction(message || type))
      dispatch(stopFetching(PURCHASE))
      createPaymentResFromDigitalWallet?.complete('fail')
      return
    }

    const createSubscriptionResponse = await paymentApi.createStripeSubscription(
      {
        uuid,
        appName,
        trialPeriodDays,
        cohort,
        planId,
      },
    )

    if (
      !createSubscriptionResponse.success ||
      !createSubscriptionResponse.data
    ) {
      if (createSubscriptionResponse.status === 404) {
        dispatch(setErrorAction('Error: Link is not valid'))
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      if (createSubscriptionResponse.status === 409) {
        if (
          createSubscriptionResponse.data.error === USER_HAS_SUBSCRIPTION_ERROR
        ) {
          dispatch(setErrorAction(USER_HAS_SUBSCRIPTION_ERROR))
          dispatch(stopFetching(PURCHASE))
          return
        }
        dispatch(setErrorAction('Error: You already have a email'))
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      dispatch(setErrorAction('Error: Something went wrong'))
      dispatch(stopFetching(PURCHASE))
      createPaymentResFromDigitalWallet?.complete('fail')
      return
    }

    dispatch(
      setPaymentClientSecretAction(
        createSubscriptionResponse.data.purchase.client_secret,
      ),
    )
    dispatch(
      setTrialPeriodDaysAction(
        createSubscriptionResponse.data.purchase.trial_period_days,
      ),
    )
    dispatch(
      setSubscriptionIdAction(
        createSubscriptionResponse.data.purchase.subscription_id,
      ),
    )

    const cardPaymentResponseFirst = await stripe.confirmCardPayment(
      createSubscriptionResponse.data.purchase.client_secret,
      {
        payment_method: createPaymentResponse.paymentMethod.id,
        save_payment_method: true,
        return_url: getRedirectUrl(paymentPageId),
      },
    )

    if (
      !cardPaymentResponseFirst?.paymentIntent &&
      cardPaymentResponseFirst?.error
    ) {
      logFailedPayment({
        productId,
        price: currentPrice,
        isTrialActive: !!trialPeriodPrice,
        paymentResponse: cardPaymentResponseFirst.error,
        paymentMethod,
        optimizeExperimentId,
        optimizeVariantId,
        optimizeSegmentName,
        stripeAccount,
        email,
        isRetry: false,
        lang,
        webapp,
      })

      if (!checkIsRetryAllowed(cardPaymentResponseFirst)) {
        dispatch(
          setErrorAction(getErrorActionPayload(cardPaymentResponseFirst.error)),
        )
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      const retryResponseFirst = await paymentApi.retryPayment({
        uuid,
        appName,
        stripeError: cardPaymentResponseFirst.error as StripeError,
      })

      if (!retryResponseFirst.data.should_retry) {
        dispatch(
          setErrorAction(getErrorActionPayload(cardPaymentResponseFirst.error)),
        )
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      dispatch(
        setPaymentClientSecretAction(
          retryResponseFirst.data.subscription.client_secret,
        ),
      )
      dispatch(
        setTrialPeriodDaysAction(
          retryResponseFirst.data.subscription.trial_period_days,
        ),
      )
      dispatch(
        setSubscriptionIdAction(
          retryResponseFirst.data.subscription.subscription_id,
        ),
      )

      const cardPaymentResponseSecond = await stripe.confirmCardPayment(
        retryResponseFirst.data.subscription.client_secret,
        {
          payment_method: createPaymentResponse.paymentMethod.id,
          save_payment_method: true,
          return_url: getRedirectUrl(paymentPageId),
        },
      )

      if (
        cardPaymentResponseSecond?.paymentIntent &&
        !cardPaymentResponseSecond?.error
      ) {
        logSuccessfulPayment({
          price:
            cardPaymentResponseSecond.paymentIntent.amount / CENTS_IN_DOLLAR,
          trialPeriodDays:
            retryResponseFirst.data.subscription.trial_period_days,
          subscriptionId: retryResponseFirst.data.subscription.subscription_id,
          discountApplied:
            retryResponseFirst.data.subscription.discount_applied,
          paymentMethod,
          productId,
          trialPrice: trialPeriodPrice,
          uuid,
          periodName,
          periodQuantity,
          optimizeExperimentId,
          optimizeVariantId,
          optimizeSegmentName,
          email,
          cohort,
          screenName,
          productPrice,
          stripeAccount,
          currency,
          lang,
          webapp,
        })

        if (cohort === 'woofz_vc2') {
          // add for video coaching web subscription
          setVCSubscriptionData()
          await sendInfoToGoogleSheet(
            { ...calendlyBody, ...userAnswers },
            cohort,
          )
        }

        dispatch(setPaymentSystemAction(PaymentSystem.STRIPE))
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('success')
        return
      }

      logFailedPayment({
        paymentResponse: cardPaymentResponseSecond.error,
        paymentMethod,
        productId,
        price: currentPrice,
        isTrialActive: !!trialPeriodPrice,
        optimizeExperimentId,
        optimizeVariantId,
        optimizeSegmentName,
        stripeAccount,
        email,
        currency,
        isRetry: true,
        lang,
        webapp,
      })

      if (!checkIsRetryAllowed(cardPaymentResponseSecond)) {
        dispatch(
          setErrorAction(
            getErrorActionPayload(cardPaymentResponseSecond.error),
          ),
        )
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      const retryResponseSecond = await paymentApi.retryPayment({
        uuid,
        appName,
        stripeError: cardPaymentResponseSecond.error as StripeError,
      })

      if (!retryResponseSecond.data.should_retry) {
        dispatch(
          setErrorAction(
            getErrorActionPayload(cardPaymentResponseSecond.error),
          ),
        )
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      dispatch(
        setPaymentClientSecretAction(
          retryResponseSecond.data.subscription.client_secret,
        ),
      )
      dispatch(
        setTrialPeriodDaysAction(
          retryResponseSecond.data.subscription.trial_period_days,
        ),
      )
      dispatch(
        setSubscriptionIdAction(
          retryResponseSecond.data.subscription.subscription_id,
        ),
      )

      const cardPaymentResponseThird = await stripe.confirmCardPayment(
        retryResponseSecond.data.subscription.client_secret,
        {
          payment_method: createPaymentResponse.paymentMethod.id,
          save_payment_method: true,
          return_url: getRedirectUrl(paymentPageId),
        },
      )

      if (
        cardPaymentResponseThird?.paymentIntent &&
        !cardPaymentResponseThird?.error
      ) {
        logSuccessfulPayment({
          price:
            cardPaymentResponseThird.paymentIntent.amount / CENTS_IN_DOLLAR,
          trialPeriodDays:
            retryResponseSecond.data.subscription.trial_period_days,
          subscriptionId: retryResponseSecond.data.subscription.subscription_id,
          discountApplied:
            retryResponseSecond.data.subscription.discount_applied,
          paymentMethod,
          productId,
          trialPrice: trialPeriodPrice,
          uuid,
          periodName,
          periodQuantity,
          optimizeExperimentId,
          optimizeVariantId,
          optimizeSegmentName,
          email,
          cohort,
          screenName,
          productPrice,
          stripeAccount,
          currency,
          lang,
          webapp,
        })

        if (cohort === 'woofz_vc2') {
          // add for video coaching web subscription
          setVCSubscriptionData()
          await sendInfoToGoogleSheet(
            { ...calendlyBody, ...userAnswers },
            cohort,
          )
        }

        dispatch(setPaymentSystemAction(PaymentSystem.STRIPE))
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('success')
        return
      }

      logFailedPayment({
        paymentResponse: cardPaymentResponseThird.error,
        paymentMethod,
        productId,
        price: currentPrice,
        isTrialActive: !!trialPeriodPrice,
        optimizeExperimentId,
        optimizeVariantId,
        optimizeSegmentName,
        stripeAccount,
        email,
        currency,
        isRetry: true,
        lang,
        webapp,
      })

      if (!checkIsRetryAllowed(cardPaymentResponseThird)) {
        dispatch(
          setErrorAction(getErrorActionPayload(cardPaymentResponseThird.error)),
        )
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      const retryResponseThird = await paymentApi.retryPayment({
        uuid,
        appName,
        stripeError: cardPaymentResponseThird.error as StripeError,
      })

      if (!retryResponseThird.data.should_retry) {
        dispatch(
          setErrorAction(getErrorActionPayload(cardPaymentResponseThird.error)),
        )
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      dispatch(
        setPaymentClientSecretAction(
          retryResponseThird.data.subscription.client_secret,
        ),
      )
      dispatch(
        setTrialPeriodDaysAction(
          retryResponseThird.data.subscription.trial_period_days,
        ),
      )
      dispatch(
        setSubscriptionIdAction(
          retryResponseThird.data.subscription.subscription_id,
        ),
      )

      const cardPaymentResponseFourth = await stripe.confirmCardPayment(
        retryResponseThird.data.subscription.client_secret,
        {
          payment_method: createPaymentResponse.paymentMethod.id,
          save_payment_method: true,
          return_url: getRedirectUrl(paymentPageId),
        },
      )

      if (
        cardPaymentResponseFourth?.paymentIntent &&
        !cardPaymentResponseFourth?.error
      ) {
        logSuccessfulPayment({
          price:
            cardPaymentResponseFourth.paymentIntent.amount / CENTS_IN_DOLLAR,
          trialPeriodDays:
            retryResponseThird.data.subscription.trial_period_days,
          subscriptionId: retryResponseThird.data.subscription.subscription_id,
          discountApplied:
            retryResponseThird.data.subscription.discount_applied,
          paymentMethod,
          productId,
          trialPrice: trialPeriodPrice,
          uuid,
          periodName,
          periodQuantity,
          optimizeExperimentId,
          optimizeVariantId,
          optimizeSegmentName,
          email,
          cohort,
          screenName,
          productPrice,
          stripeAccount,
          currency,
          lang,
          webapp,
        })

        if (cohort === 'woofz_vc2') {
          // add for video coaching web subscription
          setVCSubscriptionData()
          await sendInfoToGoogleSheet(
            { ...calendlyBody, ...userAnswers },
            cohort,
          )
        }

        dispatch(setPaymentSystemAction(PaymentSystem.STRIPE))
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('success')
        return
      }

      logFailedPayment({
        paymentResponse: cardPaymentResponseFourth.error,
        paymentMethod,
        productId,
        price: currentPrice,
        isTrialActive: !!trialPeriodPrice,
        optimizeExperimentId,
        optimizeVariantId,
        optimizeSegmentName,
        stripeAccount,
        email,
        currency,
        isRetry: false,
        lang,
        webapp,
      })

      dispatch(
        setErrorAction(getErrorActionPayload(cardPaymentResponseFourth.error)),
      )

      // Needed for reset invoice on BE
      await paymentApi.retryPayment({
        uuid,
        appName,
        stripeError: cardPaymentResponseFourth.error as StripeError,
      })

      dispatch(stopFetching(PURCHASE))
      createPaymentResFromDigitalWallet?.complete('fail')
      return
    }

    const { paymentIntent } = cardPaymentResponseFirst

    if (paymentIntent.status === 'requires_payment_method') {
      eventLogger.logPurchaseFailed({
        productId,
        priceDetails: {
          price: currentPrice,
          trial: !!trialPeriodPrice,
          currency,
        },
        error: {
          type: 'requires_payment_method',
        },
        paymentMethod,
        optimizeExperimentId,
        optimizeVariantId,
        optimizeSegmentName,
        stripeAccount,
        email,
        isRetry: false,
        lang,
        webapp,
      })
      dispatch(setErrorAction('Payment failed'))
      dispatch(stopFetching(PURCHASE))
      createPaymentResFromDigitalWallet?.complete('fail')
      return
    }

    const threeDSesureURL = paymentIntent.next_action?.redirect_to_url?.url

    if (paymentIntent.status === 'requires_action' && threeDSesureURL) {
      dispatch(set3DSecureIframeUrlAction(threeDSesureURL))
      dispatch(stopFetching(PURCHASE))
      return
    }

    logSuccessfulPayment({
      productId,
      price: currentPrice,
      trialPrice: trialPeriodPrice,
      trialPeriodDays:
        createSubscriptionResponse.data.purchase.trial_period_days,
      subscriptionId: createSubscriptionResponse.data.purchase.subscription_id,
      uuid,
      periodName,
      periodQuantity,
      paymentMethod,
      optimizeExperimentId,
      optimizeVariantId,
      optimizeSegmentName,
      email,
      cohort,
      screenName,
      productPrice,
      stripeAccount,
      currency,
      lang,
      webapp,
    })

    if (cohort === 'woofz_vc2') {
      // add for video coaching web subscription
      setVCSubscriptionData()
      await sendInfoToGoogleSheet({ ...calendlyBody, ...userAnswers }, cohort)
    }

    dispatch(setPaymentSystemAction(PaymentSystem.STRIPE))
    dispatch(stopFetching(PURCHASE))
    createPaymentResFromDigitalWallet?.complete('success')
  } catch (error: any) {
    dispatch(setErrorAction(error.toString()))
    dispatch(stopFetching(PURCHASE))
    createPaymentResFromDigitalWallet?.complete('fail')
  }
}

export const check3DSecure = (stripe: Stripe): any => async (
  dispatch: TAppDispatchThunk<any>,
  getState: () => IAppState,
): Promise<void> => {
  const state = getState()
  const currentPrice = selectSubscriptionFullPrice(state)
  const trialPeriodPrice = selectSubscriptionTrialFullPrice(state)
  const periodName = selectSubscriptionPeriodName(state)
  const productPrice = selectProductPrice(state)
  const periodQuantity = selectSubscriptionPeriodQuantity(state)
  const clientSecret = selectPaymentClientSecret(state)
  const trialPeriodDays = selectTrialDurationDays(state)
  const subscriptionId = selectSubscriptionId(state)
  const uuid = selectUUID(state)
  const optimizeVariantId = selectOptimizeVariantId(state)
  const optimizeExperimentId = selectOptimizeExperimentId(state)
  const optimizeSegmentName = selectOptimizeSegmentName(state)
  const email = selectEmail(state)
  const cohort = selectCurrentVariantCohort(state)
  const answers = selectAnswers(state)
  const calendlyBody = selectCalendlyInfo(state)
  const stripeAccount = selectStripeAccount(state)
  const currency = selectCurrency(state)
  const lang = selectLanguage(state)
  const webapp = selectIsWebappFlag(state)

  const userAnswers =
    answers || JSON.parse(localStorage.getItem(VC_COHORT) || '{}') || {}

  if (!currentPrice) {
    console.error('Error: no subscriptions plan selected')
    return
  }

  if (!clientSecret) {
    console.error('Error: client secret is needed')
    return
  }

  dispatch(startFetching(CHECK_3D_SECURE))

  const productId = createProductName({
    periodName,
    periodQuantity,
    price: currentPrice,
  })

  const response = await stripe.retrievePaymentIntent(clientSecret)

  if (response.paymentIntent?.status === 'succeeded') {
    logSuccessfulPayment({
      price: currentPrice,
      productId,
      trialPrice: trialPeriodPrice,
      trialPeriodDays,
      subscriptionId,
      uuid,
      periodName,
      periodQuantity,
      optimizeExperimentId,
      optimizeVariantId,
      optimizeSegmentName,
      email,
      cohort,
      productPrice,
      stripeAccount,
      currency,
      lang,
      webapp,
    })

    if (cohort === 'woofz_vc2') {
      // add for video coaching web subscription
      setVCSubscriptionData()
      await sendInfoToGoogleSheet({ ...calendlyBody, ...userAnswers }, cohort)
    }

    dispatch(setPaymentSystemAction(PaymentSystem.STRIPE))
    dispatch(reset3DSecureIframeUrlAction())
    return
  }

  if (response.paymentIntent?.status === 'requires_payment_method') {
    eventLogger.logPurchaseFailed({
      productId,
      priceDetails: {
        price: currentPrice,
        trial: !!trialPeriodPrice,
      },
      error: {
        type: 'requires_payment_method',
      },
      optimizeExperimentId,
      optimizeVariantId,
      optimizeSegmentName,
      stripeAccount,
      email,
      isRetry: false,
      lang,
      webapp,
    })
    dispatch(reset3DSecureIframeUrlAction())
    dispatch(setErrorAction('Payment failed'))
    dispatch(stopFetching(CHECK_3D_SECURE))
    return
  }

  if (response.error) {
    const {
      error: { type, code, message },
    } = response

    eventLogger.logPurchaseFailed({
      productId,
      priceDetails: {
        price: currentPrice,
        trial: !!trialPeriodPrice,
      },
      error: {
        type,
        code,
        description: message,
      },
      optimizeExperimentId,
      optimizeVariantId,
      optimizeSegmentName,
      stripeAccount,
      email,
      isRetry: false,
      lang,
      webapp,
    })
    dispatch(reset3DSecureIframeUrlAction())
    dispatch(setErrorAction(message || type))
    dispatch(stopFetching(CHECK_3D_SECURE))
    return
  }

  dispatch(reset3DSecureIframeUrlAction())
  dispatch(setErrorAction('Error: unhandled checking 3D Secure error'))
  dispatch(stopFetching(CHECK_3D_SECURE))
}
