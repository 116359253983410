import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/remote-config'
import remoteConfigDefaults from 'assets/firebase/remote_config_defaults.json'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setFirebaseRemoteConfigAction } from 'root-redux/actions/common'
import { selectCurrentVariantCohort } from 'root-redux/selects/common'
import { getFirebaseConfig } from 'helpers/getFirebaseConfig'

export const useInitFirebase = (): void => {
  const dispatch = useDispatch()
  const firebaseConfig = getFirebaseConfig()
  const cohort = useSelector(selectCurrentVariantCohort)

  useEffect(() => {
    if (!firebaseConfig || firebase.apps.length || !cohort) return

    const initializeAndActivateRemoteConfig = async () => {
      firebase.initializeApp(firebaseConfig)
      firebase.auth().signOut()

      const remoteConfig = firebase.remoteConfig()
      remoteConfig.defaultConfig = remoteConfigDefaults

      await remoteConfig.fetchAndActivate()

      await dispatch(setFirebaseRemoteConfigAction(remoteConfig))
    }

    initializeAndActivateRemoteConfig()
  }, [dispatch, firebaseConfig, cohort])
}
