import { IRequestOptions } from 'models/api.model'
import { ENV_ADMIN_MAP, RequestMethod } from 'root-constants'
import { getCurrentEnv } from 'helpers/getCurrentEnv'

export class BreedsApi {
  getBreeds(locale: string): Promise<Response> {
    const currentEnv = getCurrentEnv()
    const url = ENV_ADMIN_MAP[currentEnv] || ENV_ADMIN_MAP.dev

    const options: IRequestOptions = {
      method: RequestMethod.GET,
    }

    return fetch(`https://${url}/api/v1/breeds?sort_by=name&locale=${locale}`, {
      method: options.method,
      credentials: 'include',
    })
  }
}
